import React, { useState } from "react";

import "./App.scss";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Dashboard from "./components/Dashboard/Dashboard";
import Login from "./Login/Login";
import Loginone from "./Login/Loginone";
import UpdatePassword from "./components/Settings/updatePassword/UpdatePassword";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import Error1 from "./components/Error404/Error1";
import Error2 from "./components/Error404/Error2";
import Error3 from "./components/Error404/Error3";
import Error4 from "./components/Error404/Error4";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Notification from "./components/Notification/Notification";

function App() {
  const val = localStorage.getItem("accessToken");
  const [token, setToken] = useState(val);
  return (
    <>
        <ToastContainer style={{ fontSize: 20 }} />
      <Router>
        <Switch>
          <Route exact path="/forgotpassword" component={ForgotPassword} />
          {/* <Route exact path="/" component={Login} /> */}
          <Route exact path="/">
            <Login setToken={setToken} />
          </Route>


          <Route exact path="/loginone">
            <Loginone setToken={setToken} />
          </Route>

          {/* <Route exact path="/loginone" component={Loginone} /> */}
          {token && token !== null && token !== undefined ? (
            <>
              <Route exact path="/dashboard" component={Dashboard} />
              <Route exact path="/updatepassword" component={UpdatePassword} />
              <Route exact path="/notification" component={Notification} />
            </>
          ) : (
            <Redirect to="/" />
          )}

          {/* error pages routes................ */}
          <Route exact path="/badrequest" component={Error1} />
          <Route exact path="/pagenotfound" component={Error2} />
          <Route exact path="/servererror" component={Error3} />
          <Route exact path="/goneerror" component={Error4} />

         
        </Switch>
      </Router>
    </>
  );
}

export default App;
