import React, { useEffect, useState } from 'react'
import { Accordion } from 'react-bootstrap'
import { Api_Url } from "../../utils/ApiUrl";
import axios from "axios";
import moment from "moment";
import ReactPaginate from 'react-paginate';
const DomainDetails = () => {
  const aToken = localStorage.getItem("accessToken");
  const [domains, setDomains] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [orderField, setOrderField] = useState('')
  const [orderDirection, setOrderDirection] = useState()
  const [limit, setLimit] = useState(10)
  const [pageCount, setPageCount] = useState(0)
  const [page, setPage] = useState(0);
  // console.log("seearch",orderDirection ,orderField)

  const getTdnsDomains = (pagee = 0) => {
    if (searchTerm && !orderField && !orderDirection) {
      axios.get(`${Api_Url}/tdns?offset=${pagee}&limit=${limit}&label=${searchTerm}`, { headers: { "Authorization": `Bearer ${aToken}` } })
        .then((response) => {
          // console.log("resss", response.data.data.domains)
          setDomains(response.data.data.domains)
          setPageCount(response.data.data.pages)
          setPage(0)
          window.scrollTo(0, 0)
        }).catch((err) => {
          // setOpen1(false)
          // toast.error(err.response?.data.msg, {
          //     position: "top-center",
          //     autoClose: 2000,
          // });
        })
    } else if ((orderField || orderDirection) && !searchTerm) {
      axios.get(`${Api_Url}/tdns?offset=${pagee}&limit=${limit}&orderField=${orderField}&orderDirection=${orderDirection}`, { headers: { "Authorization": `Bearer ${aToken}` } })
        .then((response) => {
          // console.log("resss", response.data.data.domains)
          setDomains(response.data.data.domains)
          setPageCount(response.data.data.pages)
          setPage(0)
          window.scrollTo(0, 0)
        }).catch((err) => {
          // setOpen1(false)
          // toast.error(err.response?.data.msg, {
          //     position: "top-center",
          //     autoClose: 2000,
          // });
        })
    } else if (orderField || orderDirection || searchTerm) {
      axios.get(`${Api_Url}/tdns?offset=${pagee}&limit=${limit}&orderField=${orderField}&label=${searchTerm}&orderDirection=${orderDirection}`, { headers: { "Authorization": `Bearer ${aToken}` } })
        .then((response) => {
          // console.log("resss", response.data.data.domains)
          setDomains(response.data.data.domains)
          setPageCount(response.data.data.pages)
          setPage(0)
          window.scrollTo(0, 0)
        }).catch((err) => {
          // setOpen1(false)
          // toast.error(err.response?.data.msg, {
          //     position: "top-center",
          //     autoClose: 2000,
          // });
        })
    } else {
      axios.get(`${Api_Url}/tdns?offset=${pagee}&limit=${limit}`, { headers: { "Authorization": `Bearer ${aToken}` } })
        .then((response) => {
          // console.log("resss", response.data.data.domains)
          setDomains(response.data.data.domains)
          setPageCount(response.data.data.pages)
          window.scrollTo(0, 0)
        }).catch((err) => {
          // setOpen1(false)
          // toast.error(err.response?.data.msg, {
          //     position: "top-center",
          //     autoClose: 2000,
          // });
        })
    }

  }

  // pagination onchge
  const handlePageClick = (e) => {

    // console.log(e,'eeeerrr')

    const selectedPage = e.selected;
    // if(e.selected === 0){
    //   selectedPage = e.selected + 1;
    // }else{
    // }
    // console.log(selectedPage,'eeee')
    // console.log("jskdjkfja", selectedPage)
    // const a = selectedPage + 1
    // setPage(selectedPage)
    getTdnsDomains(selectedPage)
    // getAllUser()
  };


  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // console.log(searchTerm)
      getTdnsDomains()
    }, 2000)

    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])


  useEffect(() => {
    getTdnsDomains()
  }, [aToken, limit, orderField, orderDirection])

  return (
    <section className="datatable domaindetails-table">
      <div class="table-responsivecolorbg">
        <div className="upper-content">
          <div className="left">
            <span>show</span>
            <div class="dropdown">
              <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                {limit}
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" onClick={() => setLimit(10)}>10</a>
                <a class="dropdown-item" onClick={() => setLimit(20)}>20</a>
                <a class="dropdown-item" onClick={() => setLimit(50)}>50</a>
                <a class="dropdown-item" onClick={() => setLimit(100)}>100</a>
              </div>
            </div>
            <span>entries</span>
          </div>
          <div className="right">
            <div className="option-field">
              <h6>Search:</h6>
              <input type="text" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>
                  <div className="parent-tag">
                    Domain <div className="filter-btn">
                      {/* <img src="\Assets\tomitoken-assets\tableup.svg" alt="img" className='img-fluid' />
                      <img src="\Assets\tomitoken-assets\tabledown.svg" alt="img" className='img-fluid' /> */}
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag" onClick={() => setOrderField('mintAmount')}>
                    Price in tomi <div className="filter-btn">
                      <img src="\Assets\tomitoken-assets\tableup.svg" alt="img" className='img-fluid' onClick={() => setOrderDirection(1)} />
                      <img src="\Assets\tomitoken-assets\tabledown.svg" alt="img" className='img-fluid' onClick={() => setOrderDirection(-1)} />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Price in USD <div className="filter-btn">
                      {/* <img src="\Assets\tomitoken-assets\tableup.svg" alt="img" className='img-fluid' />
                      <img src="\Assets\tomitoken-assets\tabledown.svg" alt="img" className='img-fluid' /> */}
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag" onClick={() => setOrderField('offers')}>
                    No. of Offers <div className="filter-btn">
                      <img src="\Assets\tomitoken-assets\tableup.svg" alt="img" className='img-fluid' onClick={() => setOrderDirection(1)} />
                      <img src="\Assets\tomitoken-assets\tabledown.svg" alt="img" className='img-fluid' onClick={() => setOrderDirection(-1)} />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag" onClick={() => setOrderField('startTime')}>
                    Minted on<div className="filter-btn">
                      <img src="\Assets\tomitoken-assets\tableup.svg" alt="img" className='img-fluid' onClick={() => setOrderDirection(1)} />
                      <img src="\Assets\tomitoken-assets\tabledown.svg" alt="img" className='img-fluid' onClick={() => setOrderDirection(-1)} />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag" onClick={() => setOrderField('highestBid')}>
                    Highest Bid<div className="filter-btn">
                      <img src="\Assets\tomitoken-assets\tableup.svg" alt="img" className='img-fluid' onClick={() => setOrderDirection(1)} />
                      <img src="\Assets\tomitoken-assets\tabledown.svg" alt="img" className='img-fluid' onClick={() => setOrderDirection(-1)} />
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {domains?.map(elem => {
                let date = new Date(elem?.startTime);
                const StackIntialDate = moment(date).format("H:mm DD-MM-YYYY");
                return (
                  < tr >
                    <td><p className='textblack'>
                      <div className='twicee-abs'>
                        {/* elem?.label?.substr(elem?.label.length - 5) */}
                        <img src='/Assets/domaindetail.svg' alt='img' className='img-fluid' /> <span className='setImagee'>{`${elem?.label}.${elem?.tld}`}</span>
                      </div>
                      {elem?.label.length >= 15 ? `${elem?.label?.slice(0, 15) + '....' + elem?.label?.substr(elem?.label.length - 5)}.${elem?.tld}` : `${elem?.label}.${elem?.tld}`}
                    </p></td>
                    <td>{(elem?.mintAmount).toFixed(2)} TOMI</td>
                    <td>$100</td>
                    {/* <td>${(elem?.mintAmount / 10 ** 18 * price).toFixed(2)}</td> */}
                    <td>{elem?.offers}</td>
                    <td>{StackIntialDate}</td>
                    <td>{(elem?.highestBid).toFixed(2)} TOMI</td>
                  </tr>
                )
              })

              }
            </tbody>
          </table>
        </div>
        <div className="accmblview d-none">
          <h6 className="accmblviewhead">Domain <div className="arrowsacc">
            {/* <img src="\Assets\tomitoken-assets\tableup.svg" alt="tableupr" className="tableup" />
            <img src="\Assets\tomitoken-assets\tabledown.svg" alt="tabledown" className="tabledown" /> */}
          </div>
          </h6>
          {domains?.map(elem => {
            let date = new Date(elem?.startTime);
            const StackIntialDate = moment(date).format("H:mm DD-MM-YYYY");
            return (
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <div className="accheadermain">
                      <div className="accmainimage">
                        <img src="\Assets\dummy.png" alt="accinnerimg" className="accinnerimg img-fluid" />
                      </div>
                      <p className="acctext">{`${elem?.label}.${elem?.tld}`}</p>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="acctexts">
                      <h6 className="textleft">Price in tomi</h6>
                      <p className="textright">{(elem?.mintAmount).toFixed(2)}</p>
                    </div>
                    <div className="acctexts">
                      <h6 className="textleft">Price in USD</h6>
                      <p className="textright">$100</p>
                    </div>
                    <div className="acctexts">
                      <h6 className="textleft">No. of Offers</h6>
                      <p className="textright">{elem?.offers}</p>
                    </div>
                    <div className="acctexts">
                      <h6 className="textleft">Minted on</h6>
                      <p className="textright">{StackIntialDate}</p>
                    </div>
                    <div className="acctexts">
                      <h6 className="textleft">Highest Bid</h6>
                      <p className="textright">{(elem?.highestBid).toFixed(2)} TOMI</p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            )
          })
          }
        </div>
        <div className="footer-content">
          <div className="left-f">
            {/* <h6>Showing 1 to 10 of 57 entries</h6> */}
          </div>
          <div className="right-f">
            {/* <nav aria-label="Page navigation example">
              <ul class="pagination">
                <li class="page-item"><a class="page-link" href="#"><img src='\Assets\tomitoken-assets\arrow1.svg' alt='img' className='img-fluid' /></a></li>
                <li class="page-item"><a class="page-link active" href="#">1</a></li>
                <li class="page-item"><a class="page-link" href="#">2</a></li>
                <li class="page-item"><a class="page-link" href="#">3</a></li>
                <li class="page-item"><a class="page-link" href="#">4</a></li>
                <li class="page-item"><a class="page-link" href="#">5</a></li>
                <li class="page-item"><a class="page-link" href="#"><img src='\Assets\tomitoken-assets\arrow2.svg' alt='img' className='img-fluid' /></a></li>
              </ul>
            </nav> */}
            {pageCount > 1 ?
              <div className="text-center">
                <ReactPaginate
                  previousLabel="<"
                  nextLabel='>'
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName="pagination"
                  activeClassName="active"
                // forcePage={page}
                ></ReactPaginate>
              </div>
              : ''}
          </div>
        </div>
      </div>
    </section >
  )
}

export default DomainDetails