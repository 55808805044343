import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../Settings.scss";
import warningIcon from "../../../Assests/warningIcon.svg";
import successfullyDone from "../../../Assests/successfully-done.svg";
import { Api_Url } from "../../../utils/ApiUrl";
import axios from "axios";
import { LocalConvenienceStoreOutlined } from "@mui/icons-material";

const ChangeEmail = ({ email, getUseer }) => {
  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);
  const [error1, setError1] = useState(false);
  const [code, setCode] = useState(false);
  const [code1, setCode1] = useState(false);
  const aToken = localStorage.getItem("accessToken");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [emaiV, setEmailV] = useState(true);
  const [confirm, setConfirm] = useState(false);

  const confirmHandle = () => {
    setEmailV(false);
    setConfirm(true);
  };
  const [inputs, setInputs] = useState({});

  const confirmSuccess = () => {
    setEmailV(false);
    setConfirm(false);
  };

  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setInputs(inputs => ({ ...inputs, [name]: value }));
  }
  // console.log(inputs, "inputs");

  const updateEmail = () => {
    axios.post(`${Api_Url}/users/update-email`, { ...inputs }, { headers: { "Authorization": `Bearer ${aToken}` } })
      .then((response) => {
        confirmSuccess()
        getUseer()
        // setUser1(response?.data?.users)
        // setPageCount(response.data.total / limit)
        // setPage(page)
        // setOpen(false)
      }).catch((err) => {
        // setOpen(false)
        // toast.error(err.response?.data.msg, {
        //   position: "top-center",
        //   autoClose: 2000,
        // });
      })
  }

  const getCodee = (prop) => {
    let a = inputs?.newEmail
    axios.post(`${Api_Url}/email-verification/create-email-verification-code`, { "email": a }, { headers: { "Authorization": `Bearer ${aToken}` } })
      .then((response) => {
        // console.log("reescode", response)
        setCode(true)
      }).catch((err) => {
        // setOpen(false)
        // toast.error(err.response?.data.msg, {
        //   position: "top-center",
        //   autoClose: 2000,
        // });
        if (err?.response?.status == 409) {
          setError(err.response?.data.message)
        }
      })
  }

  const getCodee1 = () => {
    axios.post(`${Api_Url}/email-verification/create-email-verification-code`, {}, { headers: { "Authorization": `Bearer ${aToken}` } })
      .then((response) => {
        // console.log("reescode", response)
        setCode1(true)
      }).catch((err) => {
        // setOpen(false)
        // toast.error(err.response?.data.msg, {
        //   position: "top-center",
        //   autoClose: 2000,
        // });
        if (err?.response?.status == 409) {
          setError1(err.response?.data.message)
        }
      })
  }

  return (
    <>
      <Button className="update-pass-btn ms-auto" onClick={handleShow}>
        Update Email
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-wrapper-box"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Your Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {emaiV ? (
            <div className="modal-inner-content">
              <img src={warningIcon} alt="warningIcon" className="war-img" />
              <h4>Are you sure you want to update your email?</h4>
            </div>
          ) : confirm ? (
            <div className="modal-inner-content change-email-inputs-wrapperr">
              <div className="input-wrapper-box">
                <div className="input-lable-content">
                  <p>New Email</p>
                  <div className="divider-line"></div>
                </div>
                <input placeholder="Enter new email" name="newEmail" type="email" onChange={handleChange1} />
              </div>
              <div className="input-wrapper-box email-verification">
                <div className="input-lable-content">
                  <p>New Email verification code</p>
                  <div className="divider-line"></div>
                </div>
                <input placeholder="Enter code" name="newEmailOtpToken" type="number" onChange={handleChange1} />
                <p className="get-code" onClick={() => getCodee()}>{code ? 'Code Sent' : 'Get Code'}</p>
              </div>
              {error ?
                <div style={{ position: "unset" }} className="text-danger mt-2">
                  <small>{error}</small>
                </div>
                :
                <p className="email-code">
                  Enter the 6-digit code sent to {inputs?.newEmail}. Code will expire in 20 Minutes.
                </p>
              }


              <h3 className="security-verofcation">Security Verification</h3>
              {/* <div> */}
              <div className="input-wrapper-box email-verification-2">
                <div className="input-lable-content">
                  <p>Email verification code</p>
                  <div className="divider-line"></div>
                </div>
                <input placeholder="Enter code" name="oldEmailOtpToken" type="number" onChange={handleChange1} />
                <p className="get-code" onClick={() => getCodee1()}>{code1 ? 'Code Sent' : 'Get Code'}</p>
              </div>
              {error1 ?
                <div style={{ position: "unset" }} className="text-danger mt-2">
                  <small>{error1}</small>
                </div>
                :
                <p className="email-code">
                  Enter the 6-digit code sent to {email}. Code will expire in 20 Minutes.
                </p>
              }

              <div className="input-wrapper-box email-verification-3">
                <div className="input-lable-content">
                  <p>Authenticator Code</p>
                  <div className="divider-line"></div>
                </div>
                <input placeholder="Enter code" name="FAOtpToken" type="number" onChange={handleChange1} />
                {/* <p className="get-code">Get Code</p> */}
              </div>

              <p className="email-code">
                Enter the 6-digit code from Google Authenticator
              </p>
              {/* </div> */}
            </div>
          ) : (
            <div className="modal-inner-content successfully-done">
              <img
                src={successfullyDone}
                alt="successfullyDone"
                className="war-img"
              />
              <h4>Email changed successfully</h4>
              <p>your email has changed successfully</p>
            </div>
          )}
          {/* {confirm && } */}
        </Modal.Body>
        <Modal.Footer>
          {emaiV ? (
            <>
              <Button className="outline-btn" onClick={handleClose}>
                Back
              </Button>
              <Button className="fill-btn" onClick={() => confirmHandle(true)}>
                Confirm
              </Button>
            </>
          ) : confirm ? (
            <Button
              className={inputs?.newEmail && inputs?.newEmailOtpToken && inputs?.oldEmailOtpToken && inputs?.FAOtpToken ? "fill-btn opacity-1" : "fill-btn opacity-25 bvb"}
              onClick={() => updateEmail()}
            >
              Confirm
            </Button>
          ) : null}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ChangeEmail;
