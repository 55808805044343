import React, { useState, useEffect } from 'react'
import { Accordion } from 'react-bootstrap'
import { Api_Url } from "../../utils/ApiUrl";
import ReactPaginate from 'react-paginate';
import axios from "axios";
import moment from "moment";

const TomiHolderTable = () => {
    const aToken = localStorage.getItem("accessToken");
    const [domains, setDomains] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [orderField, setOrderField] = useState('')
    const [orderDirection, setOrderDirection] = useState()
    const [limit, setLimit] = useState(10)
    const [pageCount, setPageCount] = useState(0)
    const [page, setPage] = useState(0);
    console.log("seearch", searchTerm)

    const getTomiPayHolders = (pagee = 0) => {
        if (searchTerm && !orderField && !orderDirection) {
            axios.get(`${Api_Url}/tomi-pay/token-holders?offset=${pagee}&limit=${limit}&walletAddress=${searchTerm}`, { headers: { "Authorization": `Bearer ${aToken}` } })
                .then((response) => {
                    // console.log("resss", response.data.data.domains)
                    setDomains(response.data.data.holders)
                    setPageCount(response.data.data.pages)
                    setPage(0)
                    window.scrollTo(0, 0)
                }).catch((err) => {
                    // setOpen1(false)
                    // toast.error(err.response?.data.msg, {
                    //     position: "top-center",
                    //     autoClose: 2000,
                    // });
                })
        } else if ((orderField || orderDirection) && !searchTerm) {
            axios.get(`${Api_Url}/tomi-pay/token-holders?offset=${pagee}&limit=${limit}&orderField=${orderField}&orderDirection=${orderDirection}`, { headers: { "Authorization": `Bearer ${aToken}` } })
                .then((response) => {
                    // console.log("resss", response.data.data.domains)
                    setDomains(response.data.data.holders)
                    setPageCount(response.data.data.pages)
                    setPage(0)
                    window.scrollTo(0, 0)
                }).catch((err) => {
                    // setOpen1(false)
                    // toast.error(err.response?.data.msg, {
                    //     position: "top-center",
                    //     autoClose: 2000,
                    // }); 
                })
        } else if (orderField || orderDirection || searchTerm) {
            axios.get(`${Api_Url}/tomi-pay/token-holders?offset=${pagee}&limit=${limit}&orderField=${orderField}&walletAddress=${searchTerm}&orderDirection=${orderDirection}`, { headers: { "Authorization": `Bearer ${aToken}` } })
                .then((response) => {
                    // console.log("resss", response.data.data.holders)
                    setDomains(response.data.data.holders)
                    setPageCount(response.data.data.pages)
                    setPage(0)
                    window.scrollTo(0, 0)
                }).catch((err) => {
                    // setOpen1(false)
                    // toast.error(err.response?.data.msg, {
                    //     position: "top-center",
                    //     autoClose: 2000,
                    // });
                })
        } else {
            axios.get(`${Api_Url}/tomi-pay/token-holders?offset=${pagee}&limit=${limit}`, { headers: { "Authorization": `Bearer ${aToken}` } })
                .then((response) => {
                    // console.log("resss", response.data.data.holders)
                    setDomains(response.data.data.holders)
                    setPageCount(response.data.data.pages)
                    window.scrollTo(0, 0)
                }).catch((err) => {
                    // setOpen1(false)
                    // toast.error(err.response?.data.msg, {
                    //     position: "top-center",
                    //     autoClose: 2000,
                    // });
                })
        }

    }

    // pagination onchge
    const handlePageClick = (e) => {

        // console.log(e,'eeeerrr')

        const selectedPage = e.selected;
        getTomiPayHolders(selectedPage)
    };


    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            // console.log(searchTerm)
            getTomiPayHolders()
        }, 2000)

        return () => clearTimeout(delayDebounceFn)
    }, [searchTerm])


    useEffect(() => {
        getTomiPayHolders()
    }, [aToken, limit, orderField, orderDirection])

    return (
        <section className="datatable">
            <div class="table-responsivecolorbg">
                <div className="upper-content">
                    <div className="left">
                        <span>Show</span>
                        <div class="dropdown">
                            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                {limit}
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item" onClick={() => setLimit(10)}>10</a>
                                <a class="dropdown-item" onClick={() => setLimit(20)}>20</a>
                                <a class="dropdown-item" onClick={() => setLimit(50)}>50</a>
                                <a class="dropdown-item" onClick={() => setLimit(100)}>100</a>
                            </div>
                        </div>
                        <span>entries</span>
                    </div>
                    <div className="right">
                        <div className="option-field">
                            <h6>Search:</h6>
                            <input type="text" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                        </div>
                    </div>
                </div>
                <div className="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>
                                    <div className="parent-tag">
                                        Created <div className="filter-btn" onClick={() => setOrderField('createdAt')}>
                                            <img src="\Assets\tomitoken-assets\tableup.svg" alt="img" className='img-fluid' onClick={() => setOrderDirection(1)} />
                                            <img src="\Assets\tomitoken-assets\tabledown.svg" alt="img" className='img-fluid' onClick={() => setOrderDirection(-1)} />
                                        </div>
                                    </div>
                                </th>
                                <th>
                                    <div className="parent-tag">
                                        Wallet <div className="filter-btn" onClick={() => setOrderField('walletAddress')}>
                                            <img src="\Assets\tomitoken-assets\tableup.svg" alt="img" className='img-fluid' onClick={() => setOrderDirection(1)} />
                                            <img src="\Assets\tomitoken-assets\tabledown.svg" alt="img" className='img-fluid' onClick={() => setOrderDirection(-1)} />
                                        </div>
                                    </div>
                                </th>
                                <th>
                                    <div className="parent-tag">
                                        Tomi Tokens <div className="filter-btn" onClick={() => setOrderField('tomiToken')}>
                                            <img src="\Assets\tomitoken-assets\tableup.svg" alt="img" className='img-fluid' onClick={() => setOrderDirection(1)} />
                                            <img src="\Assets\tomitoken-assets\tabledown.svg" alt="img" className='img-fluid' onClick={() => setOrderDirection(-1)} />
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* <tr>
                                <td>26 June, 2023 | 8:17</td>
                                <td>0x3ADD9F986B8502C40EE0F94D72DD8</td>
                                <td>5,000 TOMI</td>
                            </tr>
                            <tr>
                                <td>26 June, 2023 | 8:17</td>
                                <td>0x3ADD9F986B8502C40EE0F94D72DD8</td>
                                <td>5,000 TOMI</td>
                            </tr>
                            <tr>
                                <td>26 June, 2023 | 8:17</td>
                                <td>0x3ADD9F986B8502C40EE0F94D72DD8</td>
                                <td>5,000 TOMI</td>
                            </tr>
                            <tr>
                                <td>26 June, 2023 | 8:17</td>
                                <td>0x3ADD9F986B8502C40EE0F94D72DD8</td>
                                <td>5,000 TOMI</td>
                            </tr>
                            <tr>
                                <td>26 June, 2023 | 8:17</td>
                                <td>0x3ADD9F986B8502C40EE0F94D72DD8</td>
                                <td>5,000 TOMI</td>
                            </tr>
                            <tr>
                                <td>26 June, 2023 | 8:17</td>
                                <td>0x3ADD9F986B8502C40EE0F94D72DD8</td>
                                <td>5,000 TOMI</td>
                            </tr>
                            <tr>
                                <td>26 June, 2023 | 8:17</td>
                                <td>0x3ADD9F986B8502C40EE0F94D72DD8</td>
                                <td>5,000 TOMI</td>
                            </tr> */}
                            {domains?.map(elem => {
                                let date = new Date(elem?.createdAt);
                                const StackIntialDate = moment(date).format("H:mm DD-MM-YYYY");
                                return (
                                    < tr >
                                        <td>{StackIntialDate}</td>
                                        <td>{(elem?.walletAddress)}</td>
                                        <td>{parseFloat(elem?.tomiToken).toFixed(4)} TOMI</td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </table>
                </div>
                <div className="accmblview d-none">
                    <h6 className="accmblviewhead">Domain <div className="arrowsacc">
                        <img src="\Assets\tomitoken-assets\tableup.svg" alt="tableupr" className="tableup" />
                        <img src="\Assets\tomitoken-assets\tabledown.svg" alt="tabledown" className="tabledown" />
                    </div>
                    </h6>
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <div className="accheadermain">
                                    <p className="acctext" style={{ color: "var(--td-text)" }}>26 June, 2023 | 10:38</p>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="acctexts">
                                    <h6 className="textleft">Wallet</h6>
                                    <p className="textright">0x3ADD9F986B8502C...</p>
                                </div>
                                <div className="acctexts">
                                    <h6 className="textleft">Tomi Tokens</h6>
                                    <p className="textright">5,000 TOMI</p>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
                <div className="footer-content">
                <div className="left-f">
            {/* <h6>Showing 1 to 10 of 57 entries</h6> */}
          </div>
                <div className="right-f">
                        {pageCount > 1 ?
                            <div className="text-center">
                                <ReactPaginate
                                    previousLabel="<"
                                    nextLabel='>'
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName="pagination"
                                    activeClassName="active"
                                // forcePage={page}
                                ></ReactPaginate>
                            </div>
                            : ''}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TomiHolderTable