import React, { useState,useEffect } from 'react'
import "./tomipay.scss"
import TokenHoldersChart from '../TokenHoldersChart/TokenHoldersChart'
import TomiHolderTable from './TomiHolderTable'
import { Api_Url } from "../../utils/ApiUrl";
import axios from "axios";


const TomiHolder = () => {
  const [minted, setMinted] = useState([])
  const [datee, setDatee] = useState([])
  const aToken = localStorage.getItem("accessToken");
  const getStakedUsers = () => {
  
    axios.get(`${Api_Url}/tomi-pay/token-holders/charts`, { headers: { "Authorization": `Bearer ${aToken}` } })
        .then((response) => {
          let dumArry = [];
          let data = response.data.data;
          let data1 = [];
          data.map((val, i) => {
            data1.push(val.walletAddress)
            dumArry.push(parseInt(val.tomiToken))
        })
            setDatee(dumArry)
            setMinted(data1)
            // setClaimedToken(claimed)
        })
        .catch((err) => {
            // setOpen1(false)
            // toast.error(err.response?.data.msg, {
            //     position: "top-center",
            //     autoClose: 2000,
            // });
        })
}
// console.log("datallllll-----",datee,minted,calimedToken)
useEffect(() => {
        getStakedUsers()
}, [])

  return (
    <>
      <section className="tomi-holder">
        <div className="donut-chart">
        <TokenHoldersChart date={datee} minte={minted}/>
        </div>
        <h6 className='tomiholder-table-text'>All tomiPay tomi token Holders</h6>
        <div className="tomiholder-table">
            <TomiHolderTable />
        </div>
      </section>
    </>
  )
}

export default TomiHolder
