import React, { useState, useEffect } from 'react'
import './tomitoken.scss'
import TomiLiquidity from '../UsersChart/liquidity';
import TomiLiquidityNegative from '../UsersChart/liquidityNegative';
import DateRangePicker from 'rsuite/DateRangePicker';
import DatatableLiq from '../Datatable/DatatableLiq';
import { Api_Url } from "../../utils/ApiUrl";
import axios from "axios";
import moment from "moment";



const Liquidity = () => {
    const [value, setValue] = useState([])
    const [value1, setValue1] = useState([])
    const aToken = localStorage.getItem("accessToken");
    const [format, setFormat] = useState('')
    const [format1, setFormat1] = useState('')
    const [minted, setMinted] = useState([])
    const [datee, setDatee] = useState([])
    const [minted1, setMinted1] = useState([])
    const [datee1, setDatee1] = useState([])
    // console.log("exchange",exchange);
    // console.log("dateeeee", datee)

    const getStakedUsers = () => {
        let date = null;
        let date1 = null;
        if (value[0]) {
            let a = new Date(value[0])
            let b = new Date(value[1])
            date = moment(a).utc(true).format('Y-MM-DDT00:00:00')
            date1 = moment(b).utc(true).format('Y-MM-DDT23:59:59')
        }
        else {
            var date12 = new Date();
            // var datesnd = (date12.getDate() - 7)
            var dateOffset = (24 * 60 * 60 * 1000) * 7; //7 days
            var myDate = new Date();
            myDate.setTime(myDate.getTime() - dateOffset);
            // console.log("dsdssdfsdfsdf",myDate,date12)
            //     let a = new Date(datesnd)
            date = moment(myDate).utc(true).format('Y-MM-DDT00:00:00')
            date1 = moment(date12).utc(true).format('Y-MM-DDT23:59:59')
            // console.log("dateeeee",date12,datesnd, a)

        }
        // console.log("dateeeee???????-------", date, date1)


        axios.get(`${Api_Url}/exchanges/liquidity?startDate=${date}&endDate=${date1}&exchanges[]=MEXC&exchanges[]=Huobi&exchanges[]=KuCoin&exchanges[]=Gate.io&exchanges[]=Bitget&exchanges[]=Uniswap v2&exchanges[]=Bybit&exchanges[]=DigiFinex&exchanges[]=BitMart&exchanges[]=Bitget&exchanges[]=Crypto.com Exchange&exchanges[]=Poloniex&exchanges[]=CoinEx&exchanges[]=Phemex`, { headers: { "Authorization": `Bearer ${aToken}` } })
            .then((response) => {
                let dumArry = [];
                let data = response.data.data;
                let MEXC = []
                let Huobi = []
                let Bybit = []
                let KuCoin = []
                let Gateio = []
                let DigiFinex = []
                let BitMart = []
                let Bitget = []
                let CryptoExchange = []
                let Uniswapv2 = []
                let Poloniex = []
                let CoinEx = []
                let Phemex = []
                data.map((val, i) => {
                    // console.log("valllll", val)
                    const sta1 = val?._id?.day
                    const sta2 = val?._id?.hour
                    if (dumArry !== sta1 && val?._id?.day) {
                        const sta1 = moment.utc(val?.data[0].createdAt).format("DD/MM/YYYY")
                        setFormat('day')
                        dumArry.push(sta1)
                    } else if (val?._id?.hour !== undefined) {
                        setFormat('hour')
                        dumArry.push(sta2)
                    } else if (val?._id?.month) {
                        setFormat('month')
                        if (val?._id?.month === 1) {
                            dumArry.push('Jan')
                        } else if (val?._id?.month === 2) {
                            dumArry.push('Feb')
                        } else if (val?._id?.month === 3) {
                            dumArry.push('Mar')
                        } else if (val?._id?.month === 4) {
                            dumArry.push('Apr')
                        } else if (val?._id?.month === 5) {
                            dumArry.push('May')
                        } else if (val?._id?.month === 6) {
                            dumArry.push('Jun')
                        } else if (val?._id?.month === 7) {
                            dumArry.push('Jul')
                        } else if (val?._id?.month === 8) {
                            dumArry.push('Aug')
                        } else if (val?._id?.month === 9) {
                            dumArry.push('Sep')
                        } else if (val?._id?.month === 10) {
                            dumArry.push('Oct')
                        } else if (val?._id?.month === 11) {
                            dumArry.push('Nov')
                        } else if (val?._id?.month === 12) {
                            dumArry.push('Dec')
                        }
                        // dumArry.push(sta1)
                    }
                    val?.data.map((e) => {
                        
                        // if(dayy === 'day'){
                        //      dee = moment(val.createdAt).format("DD/MM/YYYY ")
                           
                        // }
                        // console.log("day",dayy)
                        if (e.exchange === "KuCoin") {
                            KuCoin.push({
                                depthPositive: parseInt(parseInt(e.depthPositive)),
                                depthNegative: parseInt(parseInt(e.depthNegative)),
                                exchange: e.exchange
                            })
                        } else if (e.exchange === "MEXC") {
                            MEXC.push({
                                depthPositive: parseInt(parseInt(e.depthPositive)),
                                depthNegative: parseInt(parseInt(e.depthNegative)),
                                exchange: e.exchange
                            })
                        } else if (e.exchange === "Huobi") {
                            Huobi.push({
                                depthPositive: parseInt(parseInt(e.depthPositive)),
                                depthNegative: parseInt(parseInt(e.depthNegative)),
                                exchange: e.exchange
                            })
                        } else if (e.exchange === "Bybit") {
                            Bybit.push({
                                depthPositive: parseInt(parseInt(e.depthPositive)),
                                depthNegative: parseInt(parseInt(e.depthNegative)),
                                exchange: e.exchange
                            })
                        } else if (e.exchange === "Gate.io") {
                            Gateio.push({
                                depthPositive: parseInt(parseInt(e.depthPositive)),
                                depthNegative: parseInt(parseInt(e.depthNegative)),
                                exchange: e.exchange
                            })
                        } else if (e.exchange === "DigiFinex") {
                            DigiFinex.push({
                                depthPositive: parseInt(parseInt(e.depthPositive)),
                                depthNegative: parseInt(parseInt(e.depthNegative)),
                                exchange: e.exchange
                            })
                        } else if (e.exchange === "BitMart") {
                            BitMart.push({
                                depthPositive: parseInt(parseInt(e.depthPositive)),
                                depthNegative: parseInt(parseInt(e.depthNegative)),
                                exchange: e.exchange
                            })
                        } else if (e.exchange === "Bitget") {
                            Bitget.push({
                                depthPositive: parseInt(parseInt(e.depthPositive)),
                                depthNegative: parseInt(parseInt(e.depthNegative)),
                                exchange: e.exchange
                            })
                        } else if (e.exchange === "Crypto.com Exchange") {
                            CryptoExchange.push({
                                depthPositive: parseInt(parseInt(e.depthPositive)),
                                depthNegative: parseInt(parseInt(e.depthNegative)),
                                exchange: e.exchange
                            })
                        } else if (e.exchange === "Uniswap v2") {
                            Uniswapv2.push({
                                depthPositive: parseInt(parseInt(e.depthPositive)),
                                depthNegative: parseInt(parseInt(e.depthNegative)),
                                exchange: e.exchange
                            })
                        } else if (e.exchange === "Poloniex") {
                            Poloniex.push({
                                depthPositive: parseInt(parseInt(e.depthPositive)),
                                depthNegative: parseInt(parseInt(e.depthNegative)),
                                exchange: e.exchange
                            })
                        } else if (e.exchange === "CoinEx") {
                            CoinEx.push({
                                depthPositive: parseInt(parseInt(e.depthPositive)),
                                depthNegative: parseInt(parseInt(e.depthNegative)),
                                exchange: e.exchange
                            })
                        } else if (e.exchange === "Phemex") {
                            Phemex.push({
                                depthPositive: parseInt(parseInt(e.depthPositive)),
                                depthNegative: parseInt(parseInt(e.depthNegative)),
                                exchange: e.exchange
                            })
                        }
                        // data1.push(parseInt(parseInt(e.depthPositive)))
                        // data2.push(parseInt(parseInt(e.depthNegative)))
                    })
                    // dumArry.push(dee)
                    
                    
                })
                const singleArray = [...KuCoin,...Gateio,...Uniswapv2,...DigiFinex,...MEXC,...Huobi,...Bybit,...BitMart,...Bitget,...Poloniex,...CoinEx,...Phemex,...CryptoExchange]
                // console.log("e,,,,,,", KuCoin,Gateio,Uniswapv2,DigiFinex,MEXC,Huobi,Bybit,BitMart,Bitget,Poloniex,CoinEx,Phemex,dumArry)
                setDatee(dumArry)
                setMinted(singleArray)
            })
            .catch((err) => {
                // setOpen1(false)
                // toast.error(err.response?.data.msg, {
                //     position: "top-center",
                //     autoClose: 2000,
                // });
            })
    }

    const getNegative = () => {
        let date = null;
        let date1 = null;
        if (value1[0]) {
            let a = new Date(value1[0])
            let b = new Date(value1[1])
            date = moment(a).utc(true).format('Y-MM-DDT00:00:00')
            date1 = moment(b).utc(true).format('Y-MM-DDT23:59:59')
        }
        else {
            var date12 = new Date();
            // var datesnd = (date12.getDate() - 7)
            var dateOffset = (24 * 60 * 60 * 1000) * 7; //7 days
            var myDate = new Date();
            myDate.setTime(myDate.getTime() - dateOffset);
            // console.log("dsdssdfsdfsdf",myDate,date12)
            //     let a = new Date(datesnd)
            date = moment(myDate).utc(true).format('Y-MM-DDT00:00:00')
            date1 = moment(date12).utc(true).format('Y-MM-DDT23:59:59')
            // console.log("dateeeee",date12,datesnd, a)

        }
        // console.log("dateeeee", date, date1)


        axios.get(`${Api_Url}/exchanges/liquidity?startDate=${date}&endDate=${date1}&exchanges[]=MEXC&exchanges[]=Huobi&exchanges[]=KuCoin&exchanges[]=Gate.io&exchanges[]=Bitget&exchanges[]=Uniswap v2&exchanges[]=Bybit&exchanges[]=DigiFinex&exchanges[]=BitMart&exchanges[]=Bitget&exchanges[]=Crypto.com Exchange&exchanges[]=Poloniex&exchanges[]=CoinEx&exchanges[]=Phemex`, { headers: { "Authorization": `Bearer ${aToken}` } })
            .then((response) => {
                let dumArry = [];
                let data = response.data.data;
                let MEXC = []
                let Huobi = []
                let Bybit = []
                let KuCoin = []
                let Gateio = []
                let DigiFinex = []
                let BitMart = []
                let Bitget = []
                let CryptoExchange = []
                let Uniswapv2 = []
                let Poloniex = []
                let CoinEx = []
                let Phemex = []
                data.map((val, i) => {
                    // console.log("valllll", val)
                    // var sta1 = val?._id?.day
                    const sta2 = val?._id?.hour
                    if ( val?._id?.day) {
                        const sta1 = moment.utc(val.data[0].createdAt).format("DD/MM/YYYY")
                        setFormat1('day')
                        dumArry.push(sta1)
                    } else if (val?._id?.hour !== undefined ) {
                        setFormat1('hour')
                        dumArry.push(sta2)
                    } else if (val?._id?.month) {
                        setFormat1('month')
                        if (val?._id?.month === 1) {
                            dumArry.push('Jan')
                        } else if (val?._id?.month === 2) {
                            dumArry.push('Feb')
                        } else if (val?._id?.month === 3) {
                            dumArry.push('Mar')
                        } else if (val?._id?.month === 4) {
                            dumArry.push('Apr')
                        } else if (val?._id?.month === 5) {
                            dumArry.push('May')
                        } else if (val?._id?.month === 6) {
                            dumArry.push('Jun')
                        } else if (val?._id?.month === 7) {
                            dumArry.push('Jul')
                        } else if (val?._id?.month === 8) {
                            dumArry.push('Aug')
                        } else if (val?._id?.month === 9) {
                            dumArry.push('Sep')
                        } else if (val?._id?.month === 10) {
                            dumArry.push('Oct')
                        } else if (val?._id?.month === 11) {
                            dumArry.push('Nov')
                        } else if (val?._id?.month === 12) {
                            dumArry.push('Dec')
                        }
                        // dumArry.push(sta1)
                    }
                    val?.data.map((e) => {
                        
                        // if(dayy === 'day'){
                        //      dee = moment(val.createdAt).format("DD/MM/YYYY ")
                           
                        // }
                        // console.log("day",dayy)
                        if (e.exchange === "KuCoin") {
                            KuCoin.push({
                                depthPositive: parseInt(parseInt(e.depthPositive)),
                                depthNegative: parseInt(parseInt(e.depthNegative)),
                                exchange: e.exchange
                            })
                        } else if (e.exchange === "MEXC") {
                            MEXC.push({
                                depthPositive: parseInt(parseInt(e.depthPositive)),
                                depthNegative: parseInt(parseInt(e.depthNegative)),
                                exchange: e.exchange
                            })
                        } else if (e.exchange === "Huobi") {
                            Huobi.push({
                                depthPositive: parseInt(parseInt(e.depthPositive)),
                                depthNegative: parseInt(parseInt(e.depthNegative)),
                                exchange: e.exchange
                            })
                        } else if (e.exchange === "Bybit") {
                            Bybit.push({
                                depthPositive: parseInt(parseInt(e.depthPositive)),
                                depthNegative: parseInt(parseInt(e.depthNegative)),
                                exchange: e.exchange
                            })
                        } else if (e.exchange === "Gate.io") {
                            Gateio.push({
                                depthPositive: parseInt(parseInt(e.depthPositive)),
                                depthNegative: parseInt(parseInt(e.depthNegative)),
                                exchange: e.exchange
                            })
                        } else if (e.exchange === "DigiFinex") {
                            DigiFinex.push({
                                depthPositive: parseInt(parseInt(e.depthPositive)),
                                depthNegative: parseInt(parseInt(e.depthNegative)),
                                exchange: e.exchange
                            })
                        } else if (e.exchange === "BitMart") {
                            BitMart.push({
                                depthPositive: parseInt(parseInt(e.depthPositive)),
                                depthNegative: parseInt(parseInt(e.depthNegative)),
                                exchange: e.exchange
                            })
                        } else if (e.exchange === "Bitget") {
                            Bitget.push({
                                depthPositive: parseInt(parseInt(e.depthPositive)),
                                depthNegative: parseInt(parseInt(e.depthNegative)),
                                exchange: e.exchange
                            })
                        } else if (e.exchange === "Crypto.com Exchange") {
                            CryptoExchange.push({
                                depthPositive: parseInt(parseInt(e.depthPositive)),
                                depthNegative: parseInt(parseInt(e.depthNegative)),
                                exchange: e.exchange
                            })
                        } else if (e.exchange === "Uniswap v2") {
                            Uniswapv2.push({
                                depthPositive: parseInt(parseInt(e.depthPositive)),
                                depthNegative: parseInt(parseInt(e.depthNegative)),
                                exchange: e.exchange
                            })
                        } else if (e.exchange === "Poloniex") {
                            Poloniex.push({
                                depthPositive: parseInt(parseInt(e.depthPositive)),
                                depthNegative: parseInt(parseInt(e.depthNegative)),
                                exchange: e.exchange
                            })
                        } else if (e.exchange === "CoinEx") {
                            CoinEx.push({
                                depthPositive: parseInt(parseInt(e.depthPositive)),
                                depthNegative: parseInt(parseInt(e.depthNegative)),
                                exchange: e.exchange
                            })
                        } else if (e.exchange === "Phemex") {
                            Phemex.push({
                                depthPositive: parseInt(parseInt(e.depthPositive)),
                                depthNegative: parseInt(parseInt(e.depthNegative)),
                                exchange: e.exchange
                            })
                        }
                        // data1.push(parseInt(parseInt(e.depthPositive)))
                        // data2.push(parseInt(parseInt(e.depthNegative)))
                    })
                    // dumArry.push(dee)
                    
                    
                })
                const singleArray = [...KuCoin,...Gateio,...Uniswapv2,...DigiFinex,...MEXC,...Huobi,...Bybit,...BitMart,...Bitget,...Poloniex,...CoinEx,...Phemex,...CryptoExchange]
                // console.log("e,,,,,,", KuCoin,Gateio,Uniswapv2,DigiFinex,MEXC,Huobi,Bybit,BitMart,Bitget,Poloniex,CoinEx,Phemex,dumArry)
                setDatee1(dumArry)
                setMinted1(singleArray)
            })
            .catch((err) => {
                // setOpen1(false)
                // toast.error(err.response?.data.msg, {
                //     position: "top-center",
                //     autoClose: 2000,
                // });
            })
    }

    useEffect(() => {
        if (value != null) {
            getStakedUsers()
        }
    }, [value])
    useEffect(() => {
        if (value1 != null) {
            getNegative()
        }
    }, [value1])
    return (
        <section className="checkout">
            {/* <div className="dropdownss mbldrpdwns d-none">
                <Dropdown className='mbldropdown d-none'>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        All Exchanges
                        <img src="\Assets\tomitoken-assets\arrow-down.svg" alt="arrowimg" className="arrowimg" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <p className="dropdown-item drop" >
                            <img src="\Assets\tomitoken-assets\exchangeimageone.svg" alt="exchangeimage" className="exchangeimage" />
                            Huobi
                        </p>
                        <p className="dropdown-item drop" >
                            <img src="\Assets\tomitoken-assets\exchangeimagetwo.svg" alt="exchangeimage" className="exchangeimage" />
                            Gate.io
                        </p>
                        <p className="dropdown-item drop" >
                            <img src="\Assets\tomitoken-assets\exchangeimagethree.svg" alt="exchangeimage" className="exchangeimage" />
                            MEXC Global
                        </p>
                        <p className="dropdown-item drop" >
                            <img src="\Assets\tomitoken-assets\exchangeimagefour.svg" alt="exchangeimage" className="exchangeimage" />
                            Crypto.com
                        </p>
                        <p className="dropdown-item drop" >
                            <img src="\Assets\tomitoken-assets\exchangeimagefive.svg" alt="exchangeimage" className="exchangeimage" />
                            Bybit
                        </p>
                        <p className="dropdown-item drop" >
                            <img src="\Assets\tomitoken-assets\exchangeimagesix.svg" alt="exchangeimage" className="exchangeimage" />
                            Bitget
                        </p>
                        <p className="dropdown-item drop" >
                            <img src="\Assets\tomitoken-assets\exchangeimageseven.svg" alt="exchangeimage" className="exchangeimage" />
                            Phemex
                        </p>
                        <p className="dropdown-item drop" >
                            <img src="\Assets\tomitoken-assets\exchangeimageeight.svg" alt="exchangeimage" className="exchangeimage" />
                            KuCoin
                        </p>
                    </Dropdown.Menu>
                </Dropdown>
                <Dropdown className='mbldropdown d-none'>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {
                            selectedItem ? <>{selectedItem}</> : "This week"}

                        <img src="\Assets\tomitoken-assets\arrow-down.svg" alt="arrowimg" className="arrowimg" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {items.map((item) => (
                            <p className="dropdown-item drop" onClick={() => setSelectedItem(item)}>
                                {item}
                            </p>))}
                    </Dropdown.Menu>
                </Dropdown>
            </div> */}
            <div>
            <DatatableLiq />
            </div>
            <div className="maingraph">
                <div className="graphtop">
                    <h5 className="graphtext">tomi Token - Liquidity(+2%)</h5>
                    <div className="dropdownss">
                        {/* <Dropdown className='webdropdown'>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                All Exchanges
                                <img src="\Assets\tomitoken-assets\arrow-down.svg" alt="arrowimg" className="arrowimg nonefordark" />
                                <img src="\Assets\tomitoken-assets\arrow-down-dark.svg" alt="arrowimg" className="arrowimg d-none showfordark" />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <p className="dropdown-item drop">
                                    <div className='chec'>
                                        <label class="material-checkbox">
                                            <input type="checkbox " onChange={(e) => { handleInputChange(e, 'allEechange') }} />
                                            <span class="checkmark"></span>

                                        </label>
                                    </div>
                                    All Exchanges

                                </p>
                                <p className="dropdown-item drop" >
                                    <div className='chec'>
                                        <label class="material-checkbox">
                                            <input type="checkbox" />
                                            <span class="checkmark"></span>

                                        </label>
                                    </div>
                                    <img src="\Assets\tomitoken-assets\exchangeimageone.svg" alt="exchangeimage" className="exchangeimage" />
                                    Huobi

                                </p>
                                <p className="dropdown-item drop" >
                                    <div className='chec'>
                                        <label class="material-checkbox">
                                            <input type="checkbox" onChange={(e) => { handleInputChange(e, 'houbi') }} />
                                            <span class="checkmark"></span>

                                        </label>
                                    </div>
                                    <img src="\Assets\tomitoken-assets\exchangeimagetwo.svg" alt="exchangeimage" className="exchangeimage" />
                                    Gate.io
                                </p>
                                <p className="dropdown-item drop" onChange={(e) => { handleInputChange(e.target.check, 'gate') }}>
                                    <div className='chec'>
                                        <label class="material-checkbox">
                                            <input type="checkbox" />
                                            <span class="checkmark"></span>

                                        </label>
                                    </div>
                                    <img src="\Assets\tomitoken-assets\exchangeimagethree.svg" alt="exchangeimage" className="exchangeimage" />
                                    MEXC Global
                                </p>
                                <p className="dropdown-item drop" >
                                    <div className='chec'>
                                        <label class="material-checkbox">
                                            <input type="checkbox" />
                                            <span class="checkmark"></span>

                                        </label>
                                    </div>
                                    <img src="\Assets\tomitoken-assets\exchangeimagefour.svg" alt="exchangeimage" className="exchangeimage" />
                                    Crypto.com
                                </p>
                                <p className="dropdown-item drop" >
                                    <div className='chec'>
                                        <label class="material-checkbox">
                                            <input type="checkbox" />
                                            <span class="checkmark"></span>

                                        </label>
                                    </div>
                                    <img src="\Assets\tomitoken-assets\exchangeimagefive.svg" alt="exchangeimage" className="exchangeimage" />
                                    Bybit
                                </p>
                                <p className="dropdown-item drop" >
                                    <div className='chec'>
                                        <label class="material-checkbox">
                                            <input type="checkbox" />
                                            <span class="checkmark"></span>

                                        </label>
                                    </div>
                                    <img src="\Assets\tomitoken-assets\exchangeimagesix.svg" alt="exchangeimage" className="exchangeimage" />
                                    Bitget
                                </p>
                                <p className="dropdown-item drop" >
                                    <div className='chec'>
                                        <label class="material-checkbox">
                                            <input type="checkbox" />
                                            <span class="checkmark"></span>

                                        </label>
                                    </div>
                                    <img src="\Assets\tomitoken-assets\exchangeimageseven.svg" alt="exchangeimage" className="exchangeimage" />
                                    Phemex
                                </p>
                                <p className="dropdown-item drop" >
                                    <div className='chec'>
                                        <label class="material-checkbox">
                                            <input type="checkbox" />
                                            <span class="checkmark"></span>

                                        </label>
                                    </div>
                                    <img src="\Assets\tomitoken-assets\exchangeimageeight.svg" alt="exchangeimage" className="exchangeimage" />
                                    KuCoin
                                </p>
                            </Dropdown.Menu>
                        </Dropdown> */}
                        <div className="custom-daterangepicker">
                        <DateRangePicker placement='bottomEnd' appearance="This week" placeholder="This week" onChange={(newValue) => setValue(newValue)} />
                        </div>
                    </div>
                </div>
                {/* <img src="\Assets\tomitoken-assets\graphimg.png" alt="graphimg" className="graphimg" /> */}
                <TomiLiquidity date={datee} minte={minted} format={format} />
            </div>
            <div className="maingraph mt-4">
                <div className="graphtop">
                    <h5 className="graphtext">tomi Token - Liquidity(-2%)</h5>
                    <div className="dropdownss">
                        <div className="custom-daterangepicker">
                        <DateRangePicker placement='bottomEnd' appearance="This week" placeholder="This week" onChange={(newValue) => setValue1(newValue)} />
                        </div>
                    </div>
                </div>
                {/* <img src="\Assets\tomitoken-assets\graphimg.png" alt="graphimg" className="graphimg" /> */}
                <TomiLiquidityNegative date={datee1} minte={minted1} format={format1} />
            </div>
            
        </section>
    )
}

export default Liquidity