import React, { useState, useRef, useEffect } from "react";
import Navbar from "../../Navbar/Navbar";
import "./UpdatePassword.scss";
import tomiAnalytics from "../../../Assests/tomiAnalytics.svg";
import analyticsBanner from "../../../Assests/analyticsBanner.svg";
import successBanner from "../../../Assests/successBanner.svg";
import LoginNavbar from "../../../Login/LoginNavbar";
import { Link ,useHistory} from "react-router-dom";
import { toast } from 'react-toastify'
import { Api_Url } from "../../../utils/ApiUrl";
import axios from "axios";

const UpdatePassword = () => {
  const [email, setEmail] = useState(false);
  const aToken = localStorage.getItem("accessToken");
  const history= useHistory()
  const [confirm, setConfirm] = useState(false);
  const [google, setGoggle] = useState(false);
  const [success, setSucess] = useState(false);
  const [notgoogle, setNotgoggle] = useState(false);
  const [current, setCurrent] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  var val1 = window.location.href;
  val1 = new URL(val1);
  var fa = val1.searchParams.get("2fa");
  console.log("fa",  fa)

  console.log("asadsfdsaf", password, confirmPassword, current)

  const [showError, setError] = useState("");

  const [values, setValues] = useState(["", "", "", "", "", ""]);
  const [OTP, setOTP] = useState("");

  const refs = useRef([]);

  const handleChange = (index, event) => {
    const { value } = event.target;

    // Check if the entered value is a number
    if (!/^\d*$/.test(value)) {
      event.target.value = "";
      return;
    }

    const updatedValues = [...values];
    updatedValues[index] = value.substring(0, 1); // Restrict to single digit

    setValues(updatedValues);
    const tempOTP = updatedValues.join("");
    setOTP(tempOTP);

    if (value.length > 0 && index < values.length - 1) {
      refs.current[index + 1].focus(); // Move to the next input field
    }
    if (OTP.length === 6) {
      // userLogin();
    }
  };
  function validatePassword(password) {
    // At least 8 characters long
    if (password.length < 8) return false;

    // At least one uppercase letter
    if (!/[A-Z]/.test(password)) return false;

    // At least one special character
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) return false;

    return true;
  }

  const ResetPassword = async () => {
    var val = window.location.href;
    val = new URL(val);
    var tok = val.searchParams.get("token");
    let data = null
    if (fa === 'false') {
      data = { currentPassword: current, newPassword: password, confirmPassword: confirmPassword, };
      let isValid = validatePassword(password);
      if (isValid) { // Fix here - we want to proceed if the password is valid
        if (password === confirmPassword) {
          // setLoader(true);
          await axios
          .post(`${Api_Url}/auth/change-password`, data,{ headers: { "Authorization": `Bearer ${aToken}` } })
            .then((res) => {
              setSucess(true)
              // if (fa === false) {
              //   setNotgoggle(true)
              // } else {
              //   // setGoggle(true)
              // }
              // toast.success("Password updated successfully!");
              // setLoader(false);
            })
            .catch((err) => {
              toast.error(err?.response?.data.message, {
                position: "top-right",
                autoClose: 2000,
              });
              // setLoader(false);
            });
        } else {
          toast?.error("Password's do not match!");
        }
      } else {
        toast?.error("Password does not match requirements!");
      }
    } else {
      setGoggle(true)
    }
  };

  const ResetPassword1 = async () => {
    var val = window.location.href;
    val = new URL(val);
    var tok = val.searchParams.get("token");
    let data = null
    data = { currentPassword: current, newPassword: password, confirmPassword: confirmPassword, otpToken: OTP };
    let isValid1 = validatePassword(password);
    if (isValid1) { // Fix here - we want to proceed if the password is valid
      if (password === confirmPassword) {
        // setLoader(true);
        await axios
          .post(`${Api_Url}/auth/change-password`, data,{ headers: { "Authorization": `Bearer ${aToken}` } })
          .then((res) => {

            setSucess(true)
            // if (fa === false) {
            //   setNotgoggle(true)
            // } else {
            //   // setGoggle(true)
            // }
            // toast.success("Password updated successfully!");
            // setLoader(false);
          })
          .catch((err) => {
            toast.error(err?.response?.data.message, {
              position: "top-right",
              autoClose: 2000,
            });
            // setLoader(false);
          });
      } else {
        toast?.error("Password's do not match!");
      }
    } else {
      toast?.error("Password does not match requirements!");
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && index > 0 && values[index] === "") {
      refs.current[index - 1].focus(); // Move to the previous input field on Backspace
    }
  };
  const handleFocus = (event) => {
    if (OTP?.length === values?.length) {
      event.target.setSelectionRange(0, 0); // Move the cursor to the start of the input field
    }
  };

  return (
    <>
      <LoginNavbar />
      <div className="update-passowrd-wrapper">
        {/* <div className='update-passowrd-navbar'>
                <Navbar />
            </div> */}
        {!email ? (
          <div className="btn-back">
            <Link
              to={{
                pathname: "/dashboard",
                state: { route: "5", tabKey: "fourth" },
              }}
            >
              {" "}
              <button>
                <img
                  src="\Assets\arrow-back.svg"
                  alt="img"
                  className="img-fluid me-2"
                />
                Back
              </button>{" "}
            </Link>
          </div>
        ) : (
          ""
        )}
        {email ? (
          <div className="btn-back">
            <button onClick={() => setEmail(!email)}>
              <img
                src="\Assets\arrow-back.svg"
                alt="img"
                className="img-fluid me-2"
              />
              Back
            </button>
          </div>
        ) : (
          ""
        )}
        <div className="update-passowrd-content-wrapper">
          <>
            {/* {!email && ( */}
            {!google && !success &&
              <div className="update-passowrd-content">
                <img src="\logo-light-mode.svg" alt="img" className="img-fluid light-theme-logo Alogo" style={{ maxWidth: "275px" }} />
                <img src="\logo-dark-mode.svg" alt="img" className="img-fluid dark-theme-logo Alogo" style={{ maxWidth: "275px" }} />
                <h4>Reset your Password</h4>
                <p>
                  Your new password must be different from previously used
                  passwords.
                </p>
                <div className="option-field ">
                  <input
                    placeholder="Current Password"
                    type={showPassword ? "text" : "password"}
                    className="password-input mb-0"
                    onChange={(e) => setCurrent(e.target.value)}
                  />
                  <img
                  onClick={() => setShowPassword(!showPassword)}
                    src="\Assets\eye.svg"
                    alt="img"
                    className="img-fluid password-icon"
                  />
                </div>
                <div className="option-field">
                  <input
                    placeholder="New Password"
                    type={showPassword1 ? "text" : "password"}
                    className="password-input mt-4"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <img
                    src="\Assets\eye.svg"
                    onClick={() => setShowPassword1(!showPassword1)}
                    alt="img"
                    className="img-fluid password-icon"
                  />
                </div>
                <div className="pass-rules">
                  <h6>Password Must:</h6>
                  <li>
                    <div className="passdot"></div>
                    Be at least 8 characters long
                  </li>
                  <li>
                    <div className="passdot"></div>
                    Have at least one uppercase
                  </li>
                  <li>
                    <div className="passdot"></div>
                    Contain at least one special character
                  </li>
                </div>
                <div className="option-field">
                  <input
                    placeholder="Confirm New Password"
                    type={showPassword2 ? "text" : "password"}
                    className="password-input"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <img
                    src="\Assets\eye.svg"
                    onClick={() => setShowPassword2(!showPassword2)}
                    alt="img"
                    className="img-fluid password-icon"
                  />
                </div>
                <button className="fill-btn" onClick={() => ResetPassword()}>
                  Confirm
                </button>
              </div>
            }
            {/* )} */}
            {/* change your password */}

            {google && !success &&
              <>
                <section className="main-login">
                  <div className="container-fluid p-0">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="maincard">
                          <div className="logincard">
                            <div className="innerlogo">
                              <img
                                src="\Assets\navbar-assets\innerlogo.svg"
                                alt="img"
                                className="img-fluid"
                              />
                            </div>
                            <div className="cardtext">
                              <h6>Enter your 2fa code</h6>
                              <p>
                                Enter 6-digit code from your two factor authentication APP
                              </p>
                            </div>
                            <div className="parentinputs">
                              {values.map((value, index) => (
                                <input
                                  autoFocus={index === 0}
                                  onFocus={handleFocus}
                                  className="code"
                                  type="text"
                                  placeholder="_"
                                  key={index}
                                  value={value}
                                  onChange={(event) => handleChange(index, event)}
                                  onKeyDown={(event) => handleKeyDown(index, event)}
                                  ref={(inputRef) => (refs.current[index] = inputRef)}
                                />
                              ))}
                            </div>
                            <div
                              style={{ position: "unset" }}
                              className="text-danger mt-4"
                            >
                              <small>{showError}</small>
                            </div>
                            {OTP.length < 6 ? (
                              <div className="digitbtns">
                                <button>{6 - OTP?.length} digits left</button>
                              </div>
                            ) : (
                              <div className="endbtn">
                                <button onClick={() => ResetPassword1()}>Confirm</button>
                              </div>
                            )}
                            <div className="bottom-text">
                              {/* <h6>Lost your 2FA Code? <a>Disable 2FA Code</a></h6> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </>
            }


            {/* Reset your Password */}
          </>
          {success &&
            <div className="update-passowrd-content">
              <img src="\logo-light-mode.svg" alt="img" className="img-fluid light-theme-logo Alogo" style={{ maxWidth: "275px" }} />
              <img src="\logo-dark-mode.svg" alt="img" className="img-fluid dark-theme-logo Alogo" style={{ maxWidth: "275px" }} />
              <img
                src={successBanner}
                alt="successBanner"
                className="mb-5 pb-3"
              />
              <h4>Success!</h4>
              <p className="mb-5">Your Password has been updated successfully</p>
              <button className="fill-btn" onClick={()=>  history.push("/dashboard")}>Done</button>
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default UpdatePassword;
