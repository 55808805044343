import React, { useState } from 'react';
import tomiAnalytics from '../../Assests/tomiAnalytics.svg';
import analyticsBanner from '../../Assests/analyticsBanner.svg';
import successBanner from '../../Assests/successBanner.svg';
import LoginNavbar from '../../Login/LoginNavbar';
import { Link } from 'react-router-dom';
import { Api_Url } from "../../utils/ApiUrl";
import axios from "axios";
import { toast } from 'react-toastify';



const ForgotPassword = () => {
    const [inputs, setInputs] = useState({});

    const handleChange1 = (e) => {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    // console.log("inputsss",inputs)

    const updatePassword = () => {
        axios.post(`${Api_Url}/auth/forget-password`, { ...inputs })
            .then((response) => {
                toast.success('Email Send, Please Check Your Email For Password Reset', {
                    position: "top-center",
                    autoClose: 3000,
                  });
                  
                // confirmSuccess()
                // getUseer()
                // setUser1(response?.data?.users)
                // setPageCount(response.data.total / limit)
                // setPage(page)
                // setOpen(false)
            }).catch((err) => {
                // setOpen(false)
                toast.error(err.response?.data.msg, {
                  position: "top-center",
                  autoClose: 2000,
                });
            })
    }
    
    return (
        <>
            <LoginNavbar />
            <div className='update-passowrd-wrapper'>
                {/* <div className='update-passowrd-navbar'>
                <Navbar />
            </div> */}
            <Link to='/'>
                        <button ><img src="\Assets\arrow-back.svg" alt="img" className='img-fluid me-2' />Back</button>
            </Link>
                {/* {
                    email && <div className="btn-back">
                        <button onClick={() => (setEmail(!email))}><img src="\Assets\arrow-back.svg" alt="img" className='img-fluid me-2' />Back</button>

                    </div>
                } */}

                <div className='update-passowrd-content-wrapper'>

                    <div className='update-passowrd-content'>
                    <img src="\logo-light-mode.svg" alt="img" className="img-fluid light-theme-logo Alogo" style={{maxWidth: "275px"}} />
                      <img src="\logo-dark-mode.svg" alt="img" className="img-fluid dark-theme-logo Alogo" style={{maxWidth: "275px"}}  />
                        <img src={analyticsBanner} alt='analyticsBanner' className='ABanner' />
                        <h4>Forgot your password?</h4>
                        <p>Enter your registered email to receive password reset instructions.</p>
                        <input placeholder='Email Address' type='text' name='email' className='email-input' onChange={handleChange1} />
                        <button className='fill-btn' onClick={() => updatePassword()}>Send</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword