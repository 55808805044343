import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import './TokenHoldersChart.scss';

const TokenHoldersChart = (datee) => {
    const datnew = datee;
    var format = datnew.minte
    var dateee = datnew?.date
    // console.log("dataaaaa",datnew)
    const state = {
        series: dateee,
        options: {
            labels:format,
            chart: {
                width: 329,
                type: 'donut',
            },
            dataLabels: {
                enabled: true
            },
            tooltip: {
                enabled: true,  // Set this to false to hide the tooltip on hover
                y: {
                    formatter: function (label, { format, seriesIndex, w }) {
                        var customLabels = format
                        return 'Token:' + label ;
                    }
                },
              
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        show: false
                    }
                }
            }],
            legend: {
                position: 'right',
                offsetY: 0,
                height: 430,
            },
            stroke: {
                show: false,
                width: 0,
                colors: ['transparent']
            },
        },


    };


    // appendData() {
    //     var arr = this.state.series.slice()
    //     arr.push(Math.floor(Math.random() * (100 - 1 + 1)) + 1)

    //     this.setState({
    //         series: arr
    //     })
    // }

    // removeData() {
    //     if (this.state.series.length === 1) return

    //     var arr = this.state.series.slice()
    //     arr.pop()

    //     this.setState({
    //         series: arr
    //     })
    // }

    // randomize() {
    //     this.setState({
    //         series: this.state.series.map(function () {
    //             return Math.floor(Math.random() * (100 - 1 + 1)) + 1
    //         })
    //     })
    // }

    // reset() {
    //     this.setState({
    //         series: [45, 55, 13, 33, 32, 12, 129]
    //     })
    // }



    return (


        <div className="token-holder-chart-item1">
            <h6 className="tomiholder-text">Top 100 tomiPay tomi token Holders</h6>
            <div class="chart-wrap">
                <div id="chart">
                    <ReactApexChart options={state.options} series={state.series} type="donut" width={329} />
                </div>
            </div>
        </div>


    );
}


export default TokenHoldersChart;