import React, { useState,useEffect } from 'react'
import DateRangePicker from 'rsuite/DateRangePicker';
import TomiTvl from '../UsersChart/tvl';
import { Api_Url } from "../../utils/ApiUrl";
import axios from "axios";
import moment from "moment";


const TVL = () => {
    const [value, setValue] = useState([])
    const [minted, setMinted] = useState([])
    const [datee, setDatee] = useState([])
    const [format, setFormat] = useState('')
    const aToken = localStorage.getItem("accessToken");
    const getStakedUsers = () => {
        let date = null;
        let date1 = null;
        if (value[0]) {
            let a =new Date(value[0])
            let b =new Date(value[1])
            date = moment(a).utc().format('Y-MM-DDT00:00:00')
            date1 = moment(b).utc().format('Y-MM-DDT23:59:59')
        }
        else {
            var date12 = new Date();
            var dateOffset = (24 * 60 * 60 * 1000) * 7; //7 days
            var myDate = new Date();
            myDate.setTime(myDate.getTime() - dateOffset);
            date = moment(myDate).utc().format('Y-MM-DDT00:00:00')
            date1 = moment(date12).utc().format('Y-MM-DDT23:59:59')
            // console.log("dateeeee",date12,datesnd, a)

        }
        // console.log("dateeeee",date,date1)


        axios.get(`${Api_Url}/staking/TVL?startDate=${date}&endDate=${date1}`, { headers: { "Authorization": `Bearer ${aToken}` } })
            .then((response) => {
                let dumArry = [];
                let data = response.data.data;
                let data1 = [];
                data.map((val, i) => {
                    const sta1 = moment.utc(val.createdAt).format("DD/MM/YYYY")
                    const sta2 = val?._id?.hour
                    if (dumArry !== sta1 && val?._id?.day) {
                        setFormat('day')
                        dumArry.push(sta1)
                    } else if (val?._id?.hour) {
                        setFormat('hour')
                        dumArry.push(sta2)
                    }else if(val?._id?.month){
                        setFormat('month')
                        if(val?._id?.month === 1){
                            dumArry.push('Jan')
                        }else if(val?._id?.month === 2){
                            dumArry.push('Feb')
                        }else if(val?._id?.month === 3){
                            dumArry.push('Mar')
                        }else if(val?._id?.month === 4){
                            dumArry.push('Apr')
                        }else if(val?._id?.month === 5){
                            dumArry.push('May')
                        }else if(val?._id?.month === 6){
                            dumArry.push('Jun')
                        }else if(val?._id?.month === 7){
                            dumArry.push('Jul')
                        }else if(val?._id?.month === 8){
                            dumArry.push('Aug')
                        }else if(val?._id?.month === 9){
                            dumArry.push('Sep')
                        }else if(val?._id?.month === 10){
                            dumArry.push('Oct')
                        }else if(val?._id?.month === 11){
                            dumArry.push('Nov')
                        }else if(val?._id?.month === 12){
                            dumArry.push('Dec')
                        }
                        // dumArry.push(sta1)
                    }
                    data1.push(parseInt(val.totalValueLocked))
                    // console.log("datallllll-----",parseInt(val.totalValueLocked))
                })
                setDatee(dumArry)
                setMinted(data1)
            })
            .catch((err) => {
                // setOpen1(false)
                // toast.error(err.response?.data.msg, {
                //     position: "top-center",
                //     autoClose: 2000,
                // });
            })
    }
    useEffect(() => {
        if (value != null) {
            getStakedUsers()
        }
    }, [value])
    return (
        <section className="newactiveuser">
            <div className="maingraph">
                <div className="graphtop">
                    <h5 className="graphtext">tomi Staking - TVL</h5>
                    <div className="custom-daterangepicker">
                    <DateRangePicker placement='bottomEnd' appearance="This week" placeholder="This week" onChange={(newValue) => setValue(newValue)}/>
                    </div>
                </div>
                <TomiTvl date={datee} minte={minted} format={format} />
            </div>
        </section>
    )
}

export default TVL