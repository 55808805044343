import React, { useState, useEffect } from 'react'
import { Nav } from 'react-bootstrap';
import './tomitoken.scss'
import Cashout from './Cashout';
import Liquidity from './Liquidity';
import Tokenvolume from './Tokenvolume';
import { Api_Url } from "../../utils/ApiUrl";
import axios from "axios";

const Tomitoken = () => {
    const [activeTab, setActiveTab] = useState('link-1');
    const aToken = localStorage.getItem("accessToken");
    const [value, setValue] = useState()
    console.log(value?.volume_24h, "🚀 ~ file: Tomitoken.js:14 ~ Tomitoken ~ value:", value)
    const [pos, setPos] = useState()
    const [neg, setNeg] = useState()
    const handleSelect = (eventKey) => {
        setActiveTab(eventKey);
    };
    const [update, setUpdate] = useState(0);
    // const sum = calculateSum();

    const getExchangeData = () => {
        axios.get(`${Api_Url}/market-cap`, { headers: { "Authorization": `Bearer ${aToken}` } })
            .then((response) => {
                let a = response.data.data
                var plus = 0;
                var neg1 = 0;
                const updateData = a?.map((item, i) => {
                    // console.log("imggggg",item.depth_positive_two)
                    if (item.depth_positive_two) {
                        plus += parseInt(item?.depth_positive_two)
                        neg1 += parseInt(item?.depth_negative_two)
                    }
                })
                // console.log("aaaa",plus,neg1)
                // setValue(updateData)
                setPos(plus)
                setNeg(neg1)
            })
            .catch((err) => {
                // setOpen1(false)
                // toast.error(err.response?.data.msg, {
                //     position: "top-center",
                //     autoClose: 2000,
                // });
            })
    }

    // console.log("hereereree1", value)
    const getTomiData = () => {
        // console.log("overeviewwwww")
        axios.get(`${Api_Url}/exchanges/tomi-data`, { headers: { "Authorization": `Bearer ${aToken}` } })
            .then((response) => {
                // console.log("resssp",response.data.data)
                setValue(response.data.data)
            })
            .catch((err) => {

            })
    }

    useEffect(() => {
        getTomiData()
        getExchangeData()
    }, [aToken])
    return (
        <section className="tomitoken">
            <div className="parent-overview" style={{ marginBottom: "25px" }}>
                <Nav className='maintabs' variant="pills" activeKey={activeTab} onSelect={handleSelect}>
                    <Nav.Item>
                        <Nav.Link className='tablinks' eventKey="link-1" onClick={() => setUpdate(0)}>Cashout</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link className='tablinks' eventKey="link-2" onClick={() => setUpdate(1)}>Liquidity</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link className='tablinks' eventKey="link-3" onClick={() => setUpdate(2)}>Token Volume</Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                        <Nav.Link className='tablinks' eventKey="link-4" onClick={() => setUpdate(3)}>Staking Tokens</Nav.Link>
                    </Nav.Item> */}
                </Nav>
                {update === 0 ? <>
                    <div className="right-tab">
                        <div className="inner-left">
                            <img
                                src="\Assets\new-icons\cashout.svg"
                                alt="img"
                                className="img-fluid"
                            />
                            <div className="inner-text">
                                <h6>Total Cashout</h6>
                                <p>Today</p>
                            </div>
                        </div>
                        <div className="inner-right">
                            <h5>~$ {value?.totalCashOutToday?.length > 0 ? parseInt(value?.totalCashOutToday[0]?.totalWithdrawAmount).toLocaleString() : 0}</h5>
                        </div>
                    </div>
                </> :
                    update === 1 ?
                        <>
                            <div className="right-tab custom-set-width">
                                <div className="inner-left">
                                    <img
                                        src="\Assets\new-icons\totalliquidity.svg"
                                        alt="img"
                                        className="img-fluid"
                                    />
                                    <div className="inner-text">
                                        <h6>Total Liquidity  </h6>
                                        <p>Available</p>
                                    </div>
                                </div>
                                <div className="inner-right twice-text-inner">

                                    <h5>${pos} (+2% Depth) </h5>
                                    <h5>${neg} (-2% Depth)</h5>
                                </div>
                            </div>
                        </> :
                        update === 2 ?
                            <>
                                <div className="right-tab">
                                    <div className="inner-left">
                                        <img
                                            src="\Assets\new-icons\tokenvolume.svg"
                                            alt="img"
                                            className="img-fluid"
                                        />
                                        <div className="inner-text">
                                            <h6>Total Token </h6>
                                            <p>Volume</p>
                                        </div>
                                    </div>
                                    <div className="inner-right">
                                        <h5>${value?.volume_24h ? parseInt(value?.volume_24h).toLocaleString() : ""}</h5>
                                    </div>
                                </div>
                            </>
                            : ""}
            </div>
            {activeTab === 'link-1' && (
                <>
                    <Cashout />
                </>
            )}
            {activeTab === 'link-2' && (
                <>
                    <Liquidity />
                </>
            )}
            {activeTab === 'link-3' && (
                <>
                    <Tokenvolume />
                </>
            )}
            {/* {activeTab === 'link-4' && (
                <>
                    <Stakingtoken />
                </>
            )} */}
        </section>
    )
}

export default Tomitoken