import React, { useState,useEffect } from 'react'
import { Nav } from 'react-bootstrap';
import './tomipay.scss'
import NewActiveUser from './NewActiveUser';
import TomiPayDownload from './TomiPayDownload';
import TomiPayAddressess from './TomiPayAddressess';
import TomiHolder from './TomiHolder';
import { Api_Url } from "../../utils/ApiUrl";
import axios from "axios";

const TomiPay = () => {
    const [activeTab, setActiveTab] = useState('link-1');
    const aToken = localStorage.getItem("accessToken");
    const [value, setValue] = useState([])
    const handleSelect = (eventKey) => {
        setActiveTab(eventKey);
    };

    const getTomiPayData = () => {
        // console.log("overeviewwwww")
        axios.get(`${Api_Url}/tomi-pay/data`, { headers: { "Authorization": `Bearer ${aToken}` } })
            .then((response) => {
                //    console.log("hereereree",response.data.data)
                setValue(response.data.data)
            })
            .catch((err) => {

            })
    }

    useEffect(() => {
        getTomiPayData()
    }, [aToken])


    const [update, setUpdate] = useState(0);
    return (
        <section className="tomitoken">
            <div className="parent-overview" style={{ marginBottom: "25px" }}>
                <Nav className='maintabs' variant="pills" activeKey={activeTab} onSelect={handleSelect}>
                    <Nav.Item>
                        <Nav.Link className='tablinks' eventKey="link-1" onClick={() => setUpdate(0)}>New Active Users</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link className='tablinks' eventKey="link-2" onClick={() => setUpdate(1)}>Downloads</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link className='tablinks' eventKey="link-3" onClick={() => setUpdate(2)}>Addresses</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link className='tablinks' eventKey="link-4" onClick={() => setUpdate(3)}>Tomi Holders</Nav.Link>
                    </Nav.Item>
                </Nav>
                {update === 0 ? <>
                    <div className="right-tab">
                        <div className="inner-left">
                            <img
                                src="\Assets\new-icons\tomipay.svg"
                                alt="img"
                                className="img-fluid"
                            />
                            <div className="inner-text">
                                <h6>Total Active  </h6>
                                <p>Users</p>
                            </div>
                        </div>
                        <div className="inner-right">
                            <h5>{value?.totalUsers}</h5>
                        </div>
                    </div>
                </> :
                    update === 1 ?
                        <>
                            <div className="right-tab">
                                <div className="inner-left">
                                    <img
                                        src="\Assets\new-icons\totaldownloads.svg"
                                        alt="img"
                                        className="img-fluid"
                                    />
                                    <div className="inner-text">
                                        <h6>Total Downloads</h6>
                                    </div>
                                </div>
                                <div className="inner-right">
                                    <h5>{value?.totalDownloads}</h5>
                                </div>
                            </div>
                        </> :
                         update === 2 ?
                         <>
                             <div className="right-tab">
                                 <div className="inner-left">
                                     <img
                                         src="\Assets\new-icons\totaladdresses.svg"
                                         alt="img"
                                         className="img-fluid"
                                     />
                                     <div className="inner-text">
                                         <h6>Total Addresses</h6>
                                     </div>
                                 </div>
                                 <div className="inner-right">
                                     <h5>{value?.totalAddresses}</h5>
                                 </div>
                             </div>
                         </> :
                         update === 3 ?
                         <>
                             <div className="right-tab">
                                 <div className="inner-left">
                                     <img
                                         src="\Assets\new-icons\totaltokenholders.svg"
                                         alt="img"
                                         className="img-fluid"
                                     />
                                     <div className="inner-text">
                                         <h6>Total Token Holders</h6>
                                     </div>
                                 </div>
                                 <div className="inner-right">
                                     <h5>{value?.totalTokenHolders}</h5>
                                 </div>
                             </div>
                         </>
                        : ""}
            </div>
            {activeTab === 'link-1' && (
                <>
                    <NewActiveUser />
                </>
            )}
            {activeTab === 'link-2' && (
                <>
                    <TomiPayDownload />
                </>
            )}
            {activeTab === 'link-3' && (
                <>
                    <TomiPayAddressess />
                </>
            )}
            {activeTab === 'link-4' && (
                <>
                    <TomiHolder />
                </>
            )}
        </section>
    )
}

export default TomiPay