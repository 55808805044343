import React, { useState, useEffect } from 'react'
import "./datatable.scss"
import { Accordion } from 'react-bootstrap'
import { Api_Url } from "../../utils/ApiUrl";
import axios from "axios";
import imageJson from './image.json'

const Datatable = () => {
  const [value, setValue] = useState([])
  const aToken = localStorage.getItem("accessToken");

  const getExchangeData = () => {
    axios.get(`${Api_Url}/market-cap`, { headers: { "Authorization": `Bearer ${aToken}` } })
      .then((response) => {
        let a = response.data.data
        const updateData = a?.map((item) => {
          const imageObj = imageJson.find((image) => image.id === item.exchange);
          // console.log("imggggg",imageObj)
          return {
            ...item,
            image: imageObj ? imageObj.image : ''
          }
        })
        setValue(updateData)
      })
      .catch((err) => {
        // setOpen1(false)
        // toast.error(err.response?.data.msg, {
        //     position: "top-center",
        //     autoClose: 2000,
        // });
      })
  }
  // console.log("datatat", value);
  useEffect(() => {
    getExchangeData()
  }, [aToken])
  return (
    <section className="datatable">
      <div class="table-responsivecolorbg">
        {/* <div className="upper-content">
          <div className="left">
            <span>show</span>
            <div class="dropdown">
              <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                10
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" href="#">20</a>
                <a class="dropdown-item" href="#">50</a>
                <a class="dropdown-item" href="#">100</a>
              </div>
            </div>
            <span>entries</span>
          </div>
          <div className="right">
            <div className="option-field">
              <h6>Search:</h6>
              <input type="text" />
            </div>
          </div>
        </div> */}
        <div className="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>
                  <div className="parent-tag">
                    Exchange <div className="filter-btn">
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Price <div className="filter-btn">
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Volume (24h)<div className="filter-btn">
                    </div>
                  </div>
                </th>
                <th>
                  Volume <div className="parent-tag">
                    <div className="filter-btn">
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {value?.map(elem => {
                return (
                  <tr>
                    <td><p className='textblack'><img src={elem?.image} alt='img' className='img-fluid ssss' />{elem?.exchange}</p></td>
                    <td>${parseFloat(elem?.price).toFixed(4)}</td>
                    <td>${parseInt(elem?.volume_24h).toLocaleString()}</td>
                    <td>{parseFloat(elem?.volume_percentage).toFixed(2)}%</td>
                  </tr>
                )
              })}


            </tbody>
          </table>
        </div>
        <div className="accmblview d-none">
          <h6 className="accmblviewhead">Exchange <div className="arrowsacc">
            <img src="\Assets\tomitoken-assets\tableup.svg" alt="tableupr" className="tableup" />
            <img src="\Assets\tomitoken-assets\tabledown.svg" alt="tabledown" className="tabledown" />
          </div>
          </h6>
          {value?.map(elem => {
            return (
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <div className="accheadermain">
                      <div className="accmainimage">
                        <img src={elem?.image} alt="accinnerimg" className="accinnerimg img-fluid" />
                      </div>
                      <p className="acctext">{elem?.exchange}</p>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="acctexts">
                      <h6 className="textleft">Price</h6>
                      <p className="textright">${parseFloat(elem?.price).toFixed(4)}</p>
                    </div>
                    <div className="acctexts">
                      <h6 className="textleft">Volume (24h)</h6>
                      <p className="textright">${parseInt(elem?.volume_24h).toLocaleString()}</p>
                    </div>
                    <div className="acctexts">
                      <h6 className="textleft">Volume</h6>
                      <p className="textright">{parseFloat(elem?.volume_percentage).toFixed(2)}%</p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            )
          })}
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <div className="accheadermain">
                  <div className="accmainimage">
                    <img src="\Assets\tomitoken-assets\exchangeimageone.svg" alt="accinnerimg" className="accinnerimg img-fluid" />
                  </div>
                  <p className="acctext">Huobi</p>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="acctexts">
                  <h6 className="textleft">Price</h6>
                  <p className="textright">$4.7546</p>
                </div>
                <div className="acctexts">
                  <h6 className="textleft">+2% Depth</h6>
                  <p className="textright">$677</p>
                </div>
                <div className="acctexts">
                  <h6 className="textleft">-2% Depth</h6>
                  <p className="textright">$2,605</p>
                </div>
                <div className="acctexts">
                  <h6 className="textleft">Volume (24h)</h6>
                  <p className="textright">$277,193</p>
                </div>
                <div className="acctexts">
                  <h6 className="textleft">Volume</h6>
                  <p className="textright">26.60%</p>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        {/* <div className="footer-content">
          <div className="left-f">
            <h6>Showing 1 to 10 of 57 entries</h6>
          </div>
          <div className="right-f">
            <nav aria-label="Page navigation example">
              <ul class="pagination">
                <li class="page-item"><a class="page-link" href="#"><img src='\Assets\tomitoken-assets\arrow1.svg' alt='img' className='img-fluid' /></a></li>
                <li class="page-item"><a class="page-link active" href="#">1</a></li>
                <li class="page-item"><a class="page-link" href="#">2</a></li>
                <li class="page-item"><a class="page-link" href="#">3</a></li>
                <li class="page-item"><a class="page-link" href="#">4</a></li>
                <li class="page-item"><a class="page-link" href="#">5</a></li>
                <li class="page-item"><a class="page-link" href="#"><img src='\Assets\tomitoken-assets\arrow2.svg' alt='img' className='img-fluid' /></a></li>
              </ul>
            </nav>
          </div>
        </div> */}
      </div>
    </section>
  )
}

export default Datatable