import React, { useEffect, useState } from "react";
import "./login.scss";
import { Link, useHistory } from "react-router-dom";
import LoginNavbar from "./LoginNavbar";
import axios from "axios";
import { Api_Url } from "../utils/ApiUrl";

const Login = ({ setToken }) => {
  const [userName, setUserName] = useState(null);
  const [password, setPassword] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showError, setError] = useState("");
  const history = useHistory();
  const val = localStorage.getItem("accessToken");

  useEffect(() => {
    if (val) {
      history.push("/dashboard");
    } else {
      history.push("/");
    }
  }, [val]);
  const userLogin = async () => {
    if (ValidateForm()) {
      const data = { userName: userName, password: password };
      await axios
        .post(`${Api_Url}/auth/signin`, data)
        .then((res) => {
          const resData = res?.data?.data;

          localStorage?.setItem("accessToken", resData?.token);
          setToken(resData?.token);
          localStorage?.setItem("user", JSON.stringify(resData?.user));
          history.push("/dashboard");
        })
        .catch((err) => {
          // toast.error(err?.response?.data.msg, {
          //   position: "top-right",
          //   autoClose: 2000,
          // });
          if (!err?.response?.data?.details?.otpKeyRequired) {
            setError(err?.response?.data?.message);
          } else {
            history.push({
              pathname: "/loginone",
              state: { userName: userName, password: password },
            });
          }
        });
    }
  };

  const [errors, setErrors] = useState({});

  const ValidateForm = () => {
    // let input = userInput;
    let errors = {};
    let isValid = true;

    if (!userName) {
      isValid = false;
      errors["username"] = "*Please enter a username";
    }

    if (!password) {
      isValid = false;
      errors["password"] = "*Please enter a password";
    }

    setErrors(errors);
    return isValid;
  };

  return (
    <>
      <LoginNavbar />
      <section className="main-login">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-sm-12">
              <div className="maincard">
                <div className="logincard">
                  <div className="innerlogo">
                  <img src="\logo-light-mode.svg" alt="img" className="img-fluid light-theme-logo" style={{width: "180px"}} />
                      <img src="\logo-dark-mode.svg" alt="img" className="img-fluid dark-theme-logo" style={{width: "180px"}}  />
                  </div>
                  <div className="cardtext">
                    <h6>log into your Admin account</h6>
                    <p>
                      Please enter your credentials below to log into your
                      account.
                    </p>
                    <input
                      type="text"
                      placeholder="Username"
                      value={userName}
                      onChange={(e) => {
                        setUserName(e?.target?.value);
                        let tempErrors = errors;
                        tempErrors["username"] = "";
                        setErrors(errors);
                      }}
                    />
                    <div
                      style={{ position: "unset" }}
                      className="text-danger error-margin"
                    >
                      <small>{errors.username}</small>
                    </div>
                    <div className="parent">
                      <input
                        placeholder="Password"
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={(e) => {
                          setPassword(e?.target?.value);
                          let tempErrors = errors;
                          tempErrors["password"] = "";
                          setErrors(tempErrors);
                          setError("");
                        }}
                      />
                      <div
                        style={{ position: "unset" }}
                        className="text-danger error-margin"
                      >
                        <small>{errors.password}</small>
                      </div>
                      <img
                        src="\Assets\eye.svg"
                        className="img-fluid eye-btn"
                        alt="img"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    </div>
                    <div
                      style={{ position: "unset" }}
                      className="text-danger error-margin"
                    >
                      <small>{showError}</small>
                    </div>
                  </div>
                  <div className="twice-div">
                    <div className="chec">
                      {/* <label class="material-checkbox">
                                            <input type="checkbox" />
                                            <span class="checkmark"></span>
                                            Keep me logged in
                                        </label> */}
                    </div>
                    <Link to="/forgotpassword">Forgot Password?</Link>
                  </div>
                  {/* <Link to="/loginone"> */}
                  <div className="endbtn">
                    <button onClick={() => userLogin()}>Log In</button>
                  </div>
                  {/* </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
