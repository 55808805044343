import React, { useState, useEffect } from 'react'
import './tomitoken.scss'
import DateRangePicker from 'rsuite/DateRangePicker';
import { Api_Url } from "../../utils/ApiUrl";
import { Accordion } from 'react-bootstrap'
import axios from "axios";
import moment from "moment";

const Cashout = () => {
    const [value, setValue] = useState([])
    const aToken = localStorage.getItem("accessToken");
    const [minted, setMinted] = useState([])
    const [currency, setCurrency] = useState('USDT')
    // console.log("dateeeee:::::::::", datee, minted)
    // console.log("dateeeee", currency)
    const getCashout = () => {
        let date = null;
        let date1 = null;
        if (value[0]) {
            let a = new Date(value[0])
            let b = new Date(value[1])
            date = moment(a).utc(true).format('Y-MM-DDT00:00:00')
            date1 = moment(b).utc(true).format('Y-MM-DDT23:59:59')
        }
        else {
            var date12 = new Date();
            // var datesnd = (date12.getDate() - 7)
            var dateOffset = (24 * 60 * 60 * 1000) * 31; //1 month
            var myDate = new Date();
            myDate.setTime(myDate.getTime() - dateOffset);
            // console.log("dsdssdfsdfsdf",myDate,date12)
            //     let a = new Date(datesnd)
            date = moment(myDate).utc(true).format('Y-MM-DDT00:00:00')
            date1 = moment(date12).utc(true).format('Y-MM-DDT23:59:59')

        }

        axios.get(`${Api_Url}/exchanges/cashouts?startDate=${date}&endDate=${date1}&exchanges[]=MEXC&exchanges[]=Huobi&exchanges[]=KuCoin&exchanges[]=Gate.io&exchanges[]=Bitget&exchanges[]=Uniswap v2&exchanges[]=Bybit&exchanges[]=DigiFinex&exchanges[]=BitMart&exchanges[]=Bitget&exchanges[]=Crypto.com Exchange&exchanges[]=Poloniex&exchanges[]=CoinEx&exchanges[]=Phemex&currency=${currency}`, { headers: { "Authorization": `Bearer ${aToken}` } })
            .then((response) => {
                let dumArry = [];
                let data = response.data.data;
                let a = []
                data.map((val) => {
                    // console.log("valllll", val)
                    const sta1 = val?._id?.day
                    const sta2 = val?._id?.hour
                    if (dumArry !== sta1 && val?._id?.day) {
                        const sta1 = moment.utc(val.createdAt).format("DD/MM/YYYY")
                        dumArry.push(sta1)
                    } else if (val?._id?.hour) {
                        dumArry.push(sta2)
                    } else if (val?._id?.month) {
                        if (val?._id?.month === 1) {
                            dumArry.push('Jan')
                        } else if (val?._id?.month === 2) {
                            dumArry.push('Feb')
                        } else if (val?._id?.month === 3) {
                            dumArry.push('Mar')
                        } else if (val?._id?.month === 4) {
                            dumArry.push('Apr')
                        } else if (val?._id?.month === 5) {
                            dumArry.push('May')
                        } else if (val?._id?.month === 6) {
                            dumArry.push('Jun')
                        } else if (val?._id?.month === 7) {
                            dumArry.push('Jul')
                        } else if (val?._id?.month === 8) {
                            dumArry.push('Aug')
                        } else if (val?._id?.month === 9) {
                            dumArry.push('Sep')
                        } else if (val?._id?.month === 10) {
                            dumArry.push('Oct')
                        } else if (val?._id?.month === 11) {
                            dumArry.push('Nov')
                        } else if (val?._id?.month === 12) {
                            dumArry.push('Dec')
                        }
                    }

                    val?.data.map((e) => {
                        a.push({
                            createdAt: moment.utc(e.createdAt).format("DD/MM/YYYY"),
                            withDrawAmount: parseInt(parseInt(e.withDrawAmount)),
                            exchange: e.exchange
                        })
                    })
                })
                setMinted(a)
            })
            .catch((err) => {
                // setOpen1(false)
                // toast.error(err.response?.data.msg, {
                //     position: "top-center",
                //     autoClose: 2000,
                // });
            })
    }

    useEffect(() => {
        if (value != null) {
            getCashout()
        }
    }, [value, currency])

    return (
        <section className="checkout">
            <div className="maingraph">
                <div className="graphtop">
                    <h5 className="graphtext">tomi Token - Cashout</h5>
                    <div className="twice-drop">
                        <div class="dropdown drop-custom">
                            <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {currency}
                            </button>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="#" onClick={() => setCurrency('USDT')}>USDT</a></li>
                                <li><a class="dropdown-item" href="#" onClick={() => setCurrency('TOMI')}>TOMI</a></li>
                            </ul>
                        </div>
                        <div className="dropdownss">
                            <div className="custom-daterangepicker">
                                <DateRangePicker placement='bottomEnd' appearance="This week" placeholder="Monthly" onChange={(newValue) => setValue(newValue)} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>
                                    <div className="parent-tag">
                                        Created At
                                    </div>
                                </th>
                                <th>
                                    <div className="parent-tag">
                                        Exchange
                                    </div>
                                </th>
                                <th>
                                    <div className="parent-tag">
                                        Withdraw Amount
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {minted.length > 0 ?
                                <>
                                    {minted?.map(elem => {
                                        return (
                                            < tr >
                                                <td>{elem?.createdAt}</td>
                                                <td>{elem?.exchange}</td>
                                                {currency === "USDT" ?
                                                    <td>${(elem?.withDrawAmount).toLocaleString()}</td>
                                                    :
                                                    <td>{(elem?.withDrawAmount).toLocaleString()} TOMI</td>
                                                }
                                            </tr>
                                        )
                                    })
                                    }
                                </>
                                : <p className='text-center'>No Cashout Data on given Date</p>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="accmblview d-none">
                <h6 className="accmblviewhead">tomi Token - Cashout <div className="arrowsacc">
                    {/* <img src="\Assets\tomitoken-assets\tableup.svg" alt="tableupr" className="tableup" />
            <img src="\Assets\tomitoken-assets\tabledown.svg" alt="tabledown" className="tabledown" /> */}
                </div>
                </h6>
                {minted?.map(elem => {
                    return (
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <div className="accheadermain">
                                        {/* <div className="accmainimage">
                                            <img src={elem?.image} alt="accinnerimg" className="accinnerimg img-fluid" />
                                        </div> */}
                                        <p className="acctext">{elem?.exchange}</p>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <div className="acctexts">
                                        <h6 className="textleft">Created At</h6>
                                        <p className="textright">{elem?.createdAt}</p>
                                    </div>
                                    <div className="acctexts">
                                        <h6 className="textleft">Exchange</h6>
                                        <p className="textright">{elem?.exchange}</p>
                                    </div>
                                    <div className="acctexts">
                                        <h6 className="textleft"> Withdraw Amount</h6>
                                        <p className="textright"> 
                                        {currency === "USDT" ?
                                            <td>${(elem?.withDrawAmount).toLocaleString()}</td>
                                            :
                                            <td>{(elem?.withDrawAmount).toLocaleString()} TOMI</td>
                                        }</p>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    )
                })}

            </div>
        </section>
    )
}

export default Cashout