import React from 'react'
import { Link } from 'react-router-dom'
import "./error.scss"

const Error2 = () => {
  return (
    <>
      <section className="error-page">
        <img src="\Assets\page-not-found-error.svg" alt="img" className='img-fluid' />
        <Link to="/"><button className='btn-back-error'><img src="\Assets\arrow-back.svg" alt="img" className='img-fluid me-2' />Go Back</button></Link>
      </section>
    </>
  )
}

export default Error2
