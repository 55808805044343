import React, { useEffect, useState } from 'react'
import "./notification.scss"
import { Api_Url } from "../../utils/ApiUrl";
import axios from "axios";
import moment from "moment";
import ReactPaginate from 'react-paginate';
import { io } from "socket.io-client";

const Notification = () => {
  const aToken = localStorage.getItem("accessToken");
  const [noti, setNoti] = useState([])
  const [limit, setLimit] = useState(10)
  const [pageCount, setPageCount] = useState(0)

  const getTopNotification = (pagee = 1) => {
    axios.get(`${Api_Url}/notifications?offset=${pagee}&limit=${limit}&orderField=createdAt&orderDirection=-1`, { headers: { "Authorization": `Bearer ${aToken}` } })
      .then((response) => {
        // console.log("resss", response.data.data.notifications)
        setNoti(response.data.data.notifications)
        setPageCount(response.data.data.pages)
        // if(response.data.data.notifications.length > 0){ 
        //   setDot(true)
        // }
        // window.scrollTo(0, 0)
      }).catch((err) => {
        // setOpen1(false)
        // toast.error(err.response?.data.msg, {
        //     position: "top-center",
        //     autoClose: 2000,
        // }); 
      })
  }
  const handlePageClick = (e) => {

    console.log(e, 'eeeerrr')

    const selectedPage = e.selected + 1;
    getTopNotification(selectedPage)
    // getAllUser()
  };

  const readfun = (bol, id) => {
    // console.log("resss11", bol,id)
    if (bol === false) {
      axios.patch(`${Api_Url}/notifications/` + id, { "isRead": true }, { headers: { "Authorization": `Bearer ${aToken}` } })
        .then((response) => {
          // console.log("resss", response)
          getTopNotification()
          // setNoti(response.data.data.notifications)
          // window.scrollTo(0, 0)
        }).catch((err) => {
          // setOpen1(false)
          // toast.error(err.response?.data.msg, {
          //     position: "top-center",
          //     autoClose: 2000,
          // }); 
        })
    }
  }

  useEffect(() => {
    console.log("chala")
    let socket = io({Api_Url}, {
      // transports: ["websocket", "polling"],
      // path: "/sockets",
    });
    socket.on("connect", () => {
      console.log('socket connected in header++++++++++++++++++++++++++1111');
      socket.emit("authentication", {
        token: aToken,
      });
    });

    console.log('soc', socket?.connected)

    socket.on('BUYER_ALERT', (event) => {
      console.log('socket connected in header++++++++++++++++++++++++++', socket.connected, event);
      getTopNotification()
      // setDot(true)
    });

    // socket.on("disconnect", (reason) => {
    //   console.log(`Disconnected header: ${reason}`);
    // });
  }, [])

  useEffect(() => {
    getTopNotification()
  }, [aToken]);
  return (
    <>
    <div className="wrapper-noti">
      <section className="notification-section">
        <ul class="parent-noti">
          <div className="bottom-content">
            {noti?.map((elem) => {
              // console.log("time",moment(elem?.createdAt).fromNow())
              return (
                <div className={elem?.isRead === false ? "single-noti active" : 'single-noti'} onClick={() => readfun(elem?.isRead, elem?._id)}>
                  <h6>{elem?.title}! 🐳</h6>
                  <p>${parseFloat(elem?.total).toFixed(2)} {elem?.orderType} on {elem?.exchange} exchange.</p>
                  <span className="laterago active">{moment(elem?.createdAt).fromNow()}</span>
                </div>
              )
            })}
          </div>
        </ul>
      </section>
      <div className="footer-content">
        <div className="left-f">
          {/* <h6>Showing 1 to 10 of 57 entries</h6> */}
        </div>
        <div className="right-f">
          {pageCount > 1 ?
            <div className="text-center">
              <ReactPaginate
                previousLabel="<"
                nextLabel='>'
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName="pagination"
                activeClassName="active"
              // forcePage={page}
              ></ReactPaginate>
            </div>
            : ''}
        </div>
      </div>
      </div>
    </>
  )
}

export default Notification
