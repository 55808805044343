import React from "react";
import ReactApexChart from "react-apexcharts";
import './user-chart-wrapper.scss';


const TomiLiquidityNegative = (datee) => {
        // console.log("chart??????", datee)
        const datnew = datee;
        var format = datnew.format
        var dateee = datnew?.date
        var mintee = datnew.minte
        let MEXC = []
        let Huobi = []
        let Bybit = []
        let KuCoin = []
        let Gateio = []
        let DigiFinex = []
        let BitMart = []
        let Bitget = []
        let CryptoExchange = []
        let Uniswapv2 = []
        let Poloniex = []
        let CoinEx = []
        let Phemex = []
        mintee.map((e) => {
            if (e.exchange === "KuCoin") {
                KuCoin.push(parseInt(e.depthNegative))
            } else if (e.exchange === "MEXC") {
                MEXC.push(parseInt(e.depthNegative))
            } else if (e.exchange === "Huobi") {
                Huobi.push(parseInt(e.depthNegative))
            } else if (e.exchange === "Bybit") {
                Bybit.push(parseInt(e.depthNegative))
            } else if (e.exchange === "Gate.io") {
                Gateio.push(parseInt(e.depthNegative))
            } else if (e.exchange === "DigiFinex") {
                DigiFinex.push(parseInt(e.depthNegative))
            } else if (e.exchange === "BitMart") {
                BitMart.push(parseInt(e.depthNegative))
            } else if (e.exchange === "Bitget") {
                Bitget.push(parseInt(e.depthNegative))
            } else if (e.exchange === "Crypto.com Exchange") {
                CryptoExchange.push(parseInt(e.depthNegative))
            } else if (e.exchange === "Uniswap v2") {
                Uniswapv2.push(parseInt(e.depthNegative))
            } else if (e.exchange === "Poloniex") {
                Poloniex.push(parseInt(e.depthNegative))
            } else if (e.exchange === "CoinEx") {
                CoinEx.push(parseInt(e.depthNegative))
            } else if (e.exchange === "Phemex") {
                Phemex.push(parseInt(e.depthNegative))
            }
        })


        // const [mintee] = [minte]
        // console.log("wwwerwerweweewreew", mintee, format, dateee)
        // console.log("TomiLiquidityNegative", KuCoin, Gateio, Uniswapv2, DigiFinex, MEXC, Huobi, Bybit, BitMart, Bitget, Poloniex, CoinEx, Phemex)


        const state = {

            series: [{
                name: 'KuCoin',
                data: KuCoin,
                color: "#23AF91",
                // color: '#008000'
            },
            {
                name: 'Gateio',
                data: Gateio,
                color: "#2354E6",
                // color: '#008000'
            },
            {
                name: 'Uniswapv2',
                data: Uniswapv2,
                color: "#FF007A",
                // color: '#008000'
            },
            {
                name: 'DigiFinex',
                data: DigiFinex,
                color: "#734BB9",
                // color: '#008000'
            },
            {
                name: 'MEXC',
                data: MEXC,
                color: "#1972E2",
                // color: '#008000'
            },
            {
                name: 'Bybit',
                data: Bybit,
                color: "#F7A600",
                // color: '#008000'
            },
            {
                name: 'Huobi',
                data: Huobi,
                color: "#028BD3",
                // color: '#008000'
            },
            {
                name: 'BitMart',
                data: BitMart,
                color: "#1F2020",
                // color: '#008000'
            },
            {
                name: 'Bitget',
                data: Bitget,
                color: "#1DA2B4",
                // color: '#008000'
            },
            {
                name: 'Poloniex',
                data: Poloniex,
                color: "#119E5F",
                // color: '#008000'
            }, {
                name: 'CoinEx',
                data: CoinEx,
                color: "#22E6B8",
                // color: '#008000'
            }, {
                name: 'Phemex',
                data: Phemex,
                color: "#0B5BE0",
                // color: '#008000'
            },
            {
                name: 'CryptoExchange',
                data: CryptoExchange,
                color: "#03316C",
                // color: '#008000'
            },
            ],
            options: {
                chart: {
                    height: 1000,
                    type: 'bar'
                },
                noData: {
                    text: "No data Found on Given Date",
                    align: "center",
                    verticalAlign: "middle",
                },
                // plotOptions: {
                //     bar: {
                //         horizontal: false,
                //         columnWidth: '100%',
                //         endingShape: 'rounded',
                //         // color: "#FF0083",
                //         dataLabels: {
                //             position: 'top',
                //         },

                //     },
                // },
                //     colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e',
                //     '#f48024', '#69d2e7'
                //   ],
                dataLabels: {
                    enabled: false,
                    // offsetX: 0,
                    // formatter: function(val) {
                    //     return Number(val).toLocaleString();
                    //   },
                    // style: {
                    //     fontSize: '10px',
                    //     colors: ['#000']
                    // }
                },

                xaxis: {
                    // title: {
                    //     text: "Time Period",
                    //     align: "right",
                    //     style: {
                    //         fontSize: "18px",
                    //         fontWeight: "500",
                    //         color: "var(--tooltip-text)"
                    //     }
                    // },
                    labels: {
                        formatter: function (val) {
                            if (format === 'hour') {
                                return parseFloat(val) + ':00';
                            } else if (format === 'day') {
                                return val
                            } else if (format === 'month') {
                                return val
                            }
                        }
                    },
                    axisBorder: {
                        show: true,
                        color: 'var(--datepicker-border)',
                        height: 1,
                        width: '100%',
                        offsetX: 0,
                        offsetY: 0
                    },
                    categories: [...dateee],
                    // crosshairs: {
                    //     show: false,
                    //     style: {
                    //         fontSize: '12px',
                    //         color: '#FF0083',
                    //         border: '1px solid #FF0083',
                    //     },
                    // }

                },
                yaxis: {
                    // title: {
                    //     text: "Average Staked",
                    //     style: {
                    //         fontSize: "18px",
                    //         fontWeight: "500",
                    //         color: "var(--tooltip-text)"
                    //     }
                    // },
                    labels: {
                        formatter: function (val) {
                            return Number(val).toLocaleString()
                        }
                    }

                    // crosshairs: {
                    //     show: false,
                    //     style: {
                    //         fontSize: '12px',
                    //         color: '#FF0083',
                    //         border: '1px solid #FF0083',
                    //     },
                    // }

                },

                stroke: {
                    show: true,
                    width: 2,
                    // colors: ['#FF0083']
                },


                tooltip: {
                    enabled: true,
                    // enabledOnSeries: true,  
                    shared: false,
                    followCursor: false,
                    intersect: true,
                    marker: {
                        show: false,
                    },
                    // inverseOrder: false,
                    // custom: undefined,
                    highlightDataSeries: false,
                    // fillSeriesColor: false,
                    theme: false,
                    // color: "#00000",
                    // style: {
                    //     fontSize: '12px',
                    //     color: '#00000',
                    // },
                    // marker: {
                    //     show: true,
                    // },
                    // items: {
                    //     display: 'flex',
                    // },
                    // fixed: {
                    //     enabled: false,
                    //     position: 'topBottom',
                    //     offsetX: 0,
                    //     offsetY: 0,
                    // },
                    // x: {
                    //     formatter: function (x) {
                    //         if (typeof x !== "undefined") {
                    //             if (format === 'hour') {
                    //                 return x + '/hr';
                    //             }
                    //         }
                    //         return x;
                    //     }
                    // },
                    y: {
                        formatter: function (y) {
                            if (typeof y !== "undefined") {
                                return '$' + Number(y).toLocaleString() + ' (-2%)';

                            }
                            return y;
                        }
                    }

                },


                grid: {
                    borderColor: "var(--graph-border)",
                    row: {
                        colors: ['transparent'], // takes an array which will be repeated on columns
                        opacity: 1,
                        border: 'none'
                    },
                },
                events: {
                    mouseMove: function (event, chartContext, config) {
                        var tooltip = chartContext.el.querySelector('.apexcharts-tooltip');
                        var pointsArray = config.globals.pointsArray;
                        var seriesIndex = config.seriesIndex;
                        var dataPointIndex = config.dataPointIndex === -1 ? 0 : config.dataPointIndex;

                        if (seriesIndex !== -1) {
                            var position = pointsArray[seriesIndex][dataPointIndex];

                            tooltip.style.top = position[1] + 'px';
                            tooltip.style.left = position[0] + 'px';
                        }
                    }
                }
            },


        };

        return (
            <div id="chart" className="user-chart-wrapper">
                <ReactApexChart options={state.options} series={state.series} type="bar" height={500} />
            </div>
        );
    }



export default TomiLiquidityNegative
    ;