import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../Settings.scss";
import qrImg from "../../../Assests/qrImg.svg";
import backupkeyImg from "../../../Assests/backupkey.svg";
import successfullyDone from "../../../Assests/successfully-done.svg";
import axios from "axios";
import { Api_Url } from "../../../utils/ApiUrl";
import { useHistory } from "react-router-dom";

const GoogleAuthenticator = ({
  handleClose1,
  show1,
  handleShow1,
  authenticatorOnOffBool,
  setAuthenticatorOnOffBool,
  authenticatorLinkedBool,
  setAuthenticatorLinkedBool,
  secretKey,
  setSecretKey,
  setOTP,
  OTP,
}) => {
  const history = useHistory();
  const val = localStorage.getItem("accessToken");
  const [showError, setError] = useState("");

  const [show, setShow] = useState(false);
  const [backupKey, setBackupKey] = useState(() => {
    if (authenticatorLinkedBool) return true;
    else return false;
  });
  const [authCode, setAuthCode] = useState(() => {
    if (authenticatorLinkedBool) return true;
    else return false;
  });
  const [successfully, setSuccessfully] = useState(false);
  const [QRData, setQRData] = useState("");
  const handleClose = () => {
    setShow(false);
    setOTP("");
    setSecretKey("");
    setQRData("");
    setError("");
    setBackupKey(false);
    setAuthCode(false);
    setSuccessfully(false);
  };
  const handleShow = () => setShow(true);

  const backupKeyHandle = () => {
    // handleClose();
    setBackupKey(true);
  };

  const authCodeHandle = () => {
    setAuthCode(true);
  };

  const successfullyHandle = () => {
    setSuccessfully(true);
  };

  const getKeyAndQR = async () => {
    const config = {
      method: "get",
      url: `${Api_Url}/users/get-2FA-secret`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        setSecretKey(resData?.secretKey);
        setQRData(resData?.dataURL);
      })
      .catch((err) => {
        if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/");
        }
        // toast.error(err?.response?.data.msg, {
        //   position: "top-right",
        //   autoClose: 2000,
        // });
      });
  };

  const activateDeactivateGoogleAuth = async (boolVar) => {
    if (OTP) {
      const data = {
        otpToken: OTP,
        google2FEnabled: boolVar,
        ...(!authenticatorLinkedBool && { otpSecretKey: secretKey }),
      };
      const config = {
        method: "post",
        url: `${Api_Url}/users/enable-2FA`,
        data: data,
        headers: {
          Authorization: "Bearer " + val,
        },
      };
      await axios(config)
        .then((res) => {
          const resData = res?.data?.data;
          // console.log("response: ", resData);
          setAuthenticatorLinkedBool(resData?.google2FLinked);
          setAuthenticatorOnOffBool(resData?.google2FEnabled);
          const temp = JSON.parse(localStorage.getItem("user"));
          temp.google2FLinked = resData?.google2FLinked;
          temp.google2FEnabled = resData?.google2FEnabled;
          localStorage.setItem("user", JSON.stringify(temp));
          successfullyHandle();
        })
        .catch((err) => {
          setError(err?.response?.data?.message);
          //   if (err?.response?.status == 401) {
          //     localStorage.removeItem("accessToken");
          //     history.push("/");
          //   }
          // toast.error(err?.response?.data.msg, {
          //   position: "top-right",
          //   autoClose: 2000,
          // });
        });
    } else {
      setError("Please Enter Code!");
    }
  };

  return (
    <>
      <Button
        className="google-authenticator-btn"
        //   onClick={handleShow}
      >
        <label className="switch">
          <input
            type="checkbox"
            // defaultChecked={authenticatorBool}
            checked={authenticatorOnOffBool}
            onChange={(e) => {
              if (e.target.checked) {
                handleShow();
                if (!authenticatorLinkedBool) {
                  getKeyAndQR();
                }
              } else if (!e.target.checked) {
                handleShow1();
              }
            }}
          />
          <span className="slider round"></span>
        </label>
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-wrapper-box"
      >
        <Modal.Header closeButton>
          <Modal.Title>Enable Google Authenticator</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="g-auth-moda-content">
            {!successfully ? (
              <>
                {authCode ? (
                  <div className="change-email-inputs-wrapperr">
                    <h4> Enable Authenticator by verifying your account</h4>
                    <div className="input-wrapper-box email-verification">
                      <div className="input-lable-content">
                        <p className="gsvtyscvtvssc">Authenticator Code</p>
                        <div className="divider-line"></div>
                      </div>
                      <input
                        placeholder="Enter code"
                        type="text"
                        value={OTP}
                        onChange={(e) => {
                          setOTP(e.target.value);
                          setError("");
                        }}
                      />
                      {/* <p className='get-code'>Code Send</p> */}
                      <div
                        style={{ position: "unset" }}
                        className="text-danger mt-2"
                      >
                        <small>{showError}</small>
                      </div>
                    </div>
                    {/* <div className='input-box-row'>
                                                <p className='email-code'>Enter the 6-digit code sent to joh***@gmail.com</p>
                                                <p className='resend-code'>Resend Code</p>
                                            </div>
                                            <div className='input-wrapper-box email-verification-3'>
                                                <div className='input-lable-content'>
                                                    <p>Authenticator Code</p>
                                                    <div className='divider-line'></div>
                                                </div>
                                                <input placeholder='Enter code' type='text' />
                                            </div> */}
                  </div>
                ) : (
                  <>
                    <h4>
                      {backupKey
                        ? "Save this Backup Key in a secure location"
                        : "Scan this QR code in the Authenticator app"}
                    </h4>
                    {QRData ? (
                      <img
                        src={backupKey ? backupkeyImg : QRData}
                        alt={backupKey ? "backupkeyImg" : "qrImg"}
                      />
                    ) : (
                      ""
                    )}

                    <p className="or-text">{backupKey ? "" : <>- Or -</>}</p>
                    <strong className="auth-id">{secretKey}</strong>
                    <p className="modal-inner-para-text">
                      {backupKey
                        ? "This Key will allow you to recover your Authenticator should you lose your phone. Otherwise, resetting Google Authenticator will take at least 7 days."
                        : "If you are unable to scan the QR code, please enter this code manually into the app."}
                    </p>
                  </>
                )}
              </>
            ) : (
              <>
                <div className="successfullyDone-box">
                  <img src={successfullyDone} alt="successfullyDone" />
                  <h2>Authenticator Enabled</h2>
                  <p>
                    You have successfully enabled Authenticator to protect your
                    account.
                  </p>
                </div>
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {!successfully ? (
            <>
              {backupKey ? (
                <>
                  <Button className="outline-btn" onClick={handleClose}>
                    Back
                  </Button>
                  {authCode ? (
                    <Button
                      className="fill-btn"
                      onClick={() => {
                        // successfullyHandle();
                        const res = activateDeactivateGoogleAuth(true);
                      }}
                    >
                      Next
                    </Button>
                  ) : (
                    <Button
                      className="fill-btn"
                      onClick={() => authCodeHandle()}
                    >
                      Next
                    </Button>
                  )}
                </>
              ) : (
                <Button className="fill-btn" onClick={() => backupKeyHandle()}>
                  Next
                </Button>
              )}
            </>
          ) : null}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default GoogleAuthenticator;
