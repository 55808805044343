import React, {useState, useRef } from "react";
import "./login.scss";
import { useHistory, useLocation } from "react-router-dom";
import LoginNavbar from "./LoginNavbar";
import { Modal } from "react-bootstrap";
import { Api_Url } from "../utils/ApiUrl";
import axios from "axios";
import tomiAnalytics from '../Assests/tomiAnalytics.svg';
import analyticsBanner from '../Assests/analyticsBanner.svg';
import { toast } from 'react-toastify';

const Loginone = ({setToken}) => {
  const [inputs, setInputs] = useState({});

  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setInputs(inputs => ({ ...inputs, [name]: value }));
}
  const location = useLocation();
  const { userName, password } = location.state;
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    history.push("/");
  }
  const [show11, setShow11] = useState(false);
  const handleClose11 = () => setShow11(false);

  const history = useHistory();

  const [showError, setError] = useState("");

  const [values, setValues] = useState(["", "", "", "", "", ""]);
  const [OTP, setOTP] = useState("");

  const refs = useRef([]);

  const handleChange = (index, event) => {
    const { value } = event.target;

    // Check if the entered value is a number
    if (!/^\d*$/.test(value)) {
      event.target.value = "";
      return;
    }

    const updatedValues = [...values];
    updatedValues[index] = value.substring(0, 1); // Restrict to single digit

    setValues(updatedValues);
    const tempOTP = updatedValues.join("");
    setOTP(tempOTP);

    if (value.length > 0 && index < values.length - 1) {
      refs.current[index + 1].focus(); // Move to the next input field
    }
    if (OTP.length === 6) {
      userLogin();
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && index > 0 && values[index] === "") {
      refs.current[index - 1].focus(); // Move to the previous input field on Backspace
    }
  };
  const handleFocus = (event) => {
    if (OTP?.length === values?.length) {
      event.target.setSelectionRange(0, 0); // Move the cursor to the start of the input field
    }
  };
  const userLogin = async () => {
    const data = { userName: userName, password: password, otpToken: OTP };
    await axios
      .post(`${Api_Url}/auth/signin`, data)
      .then((res) => {
        const resData = res?.data?.data;

        localStorage?.setItem("accessToken", resData?.token);
        setToken(resData?.token);

        localStorage?.setItem("user", JSON.stringify(resData?.user));
        history.push("/dashboard");
      })
      .catch((err) => {
        // toast.error(err?.response?.data.msg, {
        //   position: "top-right",
        //   autoClose: 2000,
        // });
        setError(err?.response?.data?.message);
      });
  };

  const update2Fa = () => {
    axios.post(`${Api_Url}/auth/lost-2FA`, { ...inputs })
        .then((response) => {
          setShow11(false)
          setShow(true);
              
            // confirmSuccess()
            // getUseer()
            // setUser1(response?.data?.users)
            // setPageCount(response.data.total / limit)
            // setPage(page)
            // setOpen(false)
        }).catch((err) => {
            // setOpen(false)
            toast.error(err.response?.data.msg, {
              position: "top-center",
              autoClose: 2000,
            });
        })
}

  return (
    <>
      <LoginNavbar />
      <section className="main-login">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-sm-12">
              <div className="maincard">
                <div className="logincard">
                  <div className="innerlogo">
                    <img
                      src="\Assets\navbar-assets\innerlogo.svg"
                      alt="img"
                      className="img-fluid"
                    />
                  </div>
                  <div className="cardtext">
                    <h6>Enter your 2fa code</h6>
                    <p>
                      Enter 6-digit code from your two factor authentication APP
                    </p>
                  </div>
                  <div className="parentinputs">
                    {values.map((value, index) => (
                      <input
                        autoFocus={index === 0}
                        onFocus={handleFocus}
                        className="code"
                        type="text"
                        placeholder="_"
                        key={index}
                        value={value}
                        onChange={(event) => handleChange(index, event)}
                        onKeyDown={(event) => handleKeyDown(index, event)}
                        ref={(inputRef) => (refs.current[index] = inputRef)}
                      />
                    ))}
                  </div>
                  <div
                    style={{ position: "unset" }}
                    className="text-danger mt-4"
                  >
                    <small>{showError}</small>
                  </div>
                  {OTP.length < 6 ? (
                    <div className="digitbtns">
                      <button>{6 - OTP?.length} digits left</button>
                    </div>
                  ) : (
                    <div className="endbtn">
                      <button onClick={userLogin}>Login</button>
                    </div>
                  )}
                  <div className="bottom-text">
                    <h6>Lost your 2FA Code? <a onClick={() => setShow11(true)}>Disable 2FA Code</a></h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-wrapper-box"
      >
        <Modal.Header>
          <Modal.Title>Recover 2FA Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="recover2fa">
            <img src="\Assets\2farecover.svg" alt="img" className="img-fluid" />
            <h6>Email sent successfully for 2FA Code Recovery</h6>
          </div>
          <button onClick={handleClose} className="btn-closee">Login</button>
        </Modal.Body>
      </Modal>

      <Modal
        show={show11}
        onHide={handleClose11}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="set-new-modal-for-2fa"
      >
        <Modal.Body>
        <div className='update-passowrd-wrapper'>
                <div className='update-passowrd-content-wrapper'>
                    <div className='update-passowrd-content'>
                        <img src={tomiAnalytics} alt='tomiAnalytics' className='Alogo' />
                        <img src={analyticsBanner} alt='analyticsBanner' className='ABanner' />
                        <h4>Forgot your 2FA password?</h4>
                        <p>Enter your registered email to receive 2FA password reset instructions.</p>
                        <input placeholder='Email Address' type='text' name='email' className='email-input' onChange={handleChange1}  />
                        <button className='fill-btn' onClick={() => update2Fa()}>Send</button>
                    </div>
                </div>
            </div>
        </Modal.Body>
      </Modal>
    </> 
  );
};

export default Loginone;
