import React, { useState, useEffect } from 'react'
import "./datatable.scss"
import { Accordion } from 'react-bootstrap'
import { Api_Url } from "../../utils/ApiUrl";
import axios from "axios";
import imageJson from './image.json'

const DatatableLiq = () => {
  const [value, setValue] = useState([])
  const aToken = localStorage.getItem("accessToken");

  const getExchangeData = () => {
    axios.get(`${Api_Url}/market-cap`, { headers: { "Authorization": `Bearer ${aToken}` } })
      .then((response) => {
        let a = response.data.data
        const updateData = a?.map((item) => {
          const imageObj = imageJson.find((image) => image.id === item.exchange);
          // console.log("imggggg",imageObj)
          return {
            ...item,
            image: imageObj ? imageObj.image : ''
          }
        })
        setValue(updateData)
      })
      .catch((err) => {
        // setOpen1(false)
        // toast.error(err.response?.data.msg, {
        //     position: "top-center",
        //     autoClose: 2000,
        // });
      })
  }
  // console.log("datatat", value);
  useEffect(() => {
    getExchangeData()
  }, [aToken])
  return (
    <section className="datatableLiq">
      <div class="table-responsivecolorbg">
        <div className="upper-content">
          <div className="left">
          </div>
        </div>
        <div className="table-responsive">
          <h5 className="graphtext texrcenter">tomi Token - Depth Data (+2/-2%)</h5>
          <table class="table">
            <thead>
              <tr>
                <th>
                  <div className="parent-tag">
                    Exchange <div className="filter-btn">
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    +2% Depth <div className="filter-btn">
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    -2% Depth <div className="filter-btn">
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {value?.map(elem => {
                return (
                  <tr>
                    <td><p className='textblack'><img src={elem?.image} alt='img' className='img-fluid ssss me-2' />{elem?.exchange}</p></td>
                    <td>{elem?.depth_positive_two ? `$ ${parseInt(elem?.depth_positive_two).toLocaleString()}` : '-'}</td>
                    <td>{elem?.depth_negative_two ? `$ ${parseInt(elem?.depth_negative_two).toLocaleString()}` : '-'}</td>
                  </tr>
                )
              })}


            </tbody>
          </table>
        </div>
        <div className="accmblview d-none">
          <h6 className="accmblviewhead">Exchange <div className="arrowsacc">
            {/* <img src="\Assets\tomitoken-assets\tableup.svg" alt="tableupr" className="tableup" />
            <img src="\Assets\tomitoken-assets\tabledown.svg" alt="tabledown" className="tabledown" /> */}
          </div>
          </h6>
          {value?.map(elem => {
            return (
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <div className="accheadermain">
                      <div className="accmainimage">
                        <img src={elem?.image} alt="accinnerimg" className="accinnerimg img-fluid" />
                      </div>
                      <p className="acctext">{elem?.exchange}</p>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="acctexts">
                      <h6 className="textleft">+2% Depth</h6>
                      <p className="textright">{elem?.depth_positive_two ? `$ ${parseInt(elem?.depth_positive_two).toLocaleString()}` : '-'}</p>
                    </div>
                    <div className="acctexts">
                      <h6 className="textleft">-2% Depth</h6>
                      <p className="textright">{elem?.depth_negative_two ? `$ ${parseInt(elem?.depth_negative_two).toLocaleString()}` : '-'}</p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            )
          })}

        </div>
      </div>
    </section>
  )
}

export default DatatableLiq