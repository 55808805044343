import React, { useEffect, useState } from "react";
import "./navbar.scss";
import { Api_Url } from "../../utils/ApiUrl";
import axios from "axios";
import moment from "moment";
import { io } from "socket.io-client";

const Navbar = ({ route, userObj, setroute, setCurrentTab }) => {
  const aToken = localStorage.getItem("accessToken");
  const [noti, setNoti] = useState([])
  const [dot, setDot] = useState(false)
  const [rend, setRend] = useState(false)

  const [isOpen, setIsOpen] = useState(false);

  const handleDropdownToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleDropdownClose = () => {
    setIsOpen(false);
  };

  const [theme, setTheme] = useState(
    localStorage.getItem("theme")
      ? localStorage.getItem("theme")
      : "light-theme"
  );
  const toggleTheme = () => {
    if (theme === "dark-theme") {
      setTheme("light-theme");
      localStorage.setItem("theme", "light-theme");
    } else {
      setTheme("dark-theme");
      localStorage.setItem("theme", "dark-theme");
    }
  };

  const getTopNotification = () => {
    axios.get(`${Api_Url}/notifications?offset=1&limit=5&orderField=createdAt&orderDirection=-1`, { headers: { "Authorization": `Bearer ${aToken}` } })
      .then((response) => {
        // console.log("resss", response.data.data.notifications)
        setNoti(response.data.data.notifications)
        setRend(!rend)
        // if(response.data.data.notifications.length > 0){ 
        //   setDot(true)
        // }
        // window.scrollTo(0, 0)
      }).catch((err) => {
        // setOpen1(false)
        // toast.error(err.response?.data.msg, {
        //     position: "top-center",
        //     autoClose: 2000,
        // }); 
      })
  }

  const readfun = (bol, id) => {
    // console.log("resss11", bol,id)
    if (bol === false) {
      axios.patch(`${Api_Url}/notifications/` + id, { "isRead": true }, { headers: { "Authorization": `Bearer ${aToken}` } })
        .then((response) => {
          getTopNotification()
        }).catch((err) => {
          // setOpen1(false)
          // toast.error(err.response?.data.msg, {
          //     position: "top-center",
          //     autoClose: 2000,
          // }); 
        })
    }
  }

  useEffect(() => {
    console.log("chala")
    let socket = io({Api_Url}, {
      // transports: ["websocket", "polling"],
      // path: "/sockets",
    });
    socket.on("connect", () => {
      // console.log('socket connected in header++++++++++++++++++++++++++1111');
      socket.emit("authentication", {
        token: aToken,
      });
    });

    console.log('soc', socket?.connected)

    socket.on('BUYER_ALERT', (event) => {
      // console.log('socket connected in header++++++++++++++++++++++++++', socket.connected, event);
      getTopNotification()
      setDot(true)
    });

    // socket.on("disconnect", (reason) => {
    //   console.log(`Disconnected header: ${reason}`);
    // });
  }, [])

  useEffect(() => {
    getTopNotification()
  }, [aToken]);
  useEffect(() => {
    document.body.className = theme;
  }, [theme]);
  return (
    <section className="main-navbar">
      <div className="container-fluid p-0">
        <div className="navbar">
          {route === 1 ? (
            theme === "light-theme" ? (
              <h6 className="main-head-navbar">Dashboard</h6>
            ) : (
              <h6 className="main-head-navbar">Dashboard</h6>
            )
          ) : route === 2 ? (
            theme === "light-theme" ? (
              <img
                src="\Assets\navbar-assets\tomipaylogo.svg"
                alt="logo"
                className="logo"
              />
            ) : (
              <img
                src="\Assets\navbar-assets\tomipay-dark.svg"
                alt="logo"
                className="logo"
              />
            )
          ) : route === 3 ? (
            theme === "light-theme" ? (
              <img
                src="\Assets\navbar-assets\tdnslightlogo.svg"
                alt="logo"
                className="logo"
              />
            ) : (
              <img
                src="\Assets\navbar-assets\tdnsdarklogo.svg"
                alt="logo"
                className="logo"
              />
            )
          ) : route === 4 ? (
            theme === "light-theme" ? (
              <img
                src="\Assets\navbar-assets\tomitoken-light.svg"
                alt="logo"
                className="logo"
              />
            ) : (
              <img
                src="\Assets\navbar-assets\tomitoken-dark.svg"
                alt="logo"
                className="logo"
              />
            )
          ) : route === 5 ? (
            theme === "light-theme" ? (
              <img
                src="/Assets/navbar-assets/tomistaking-light.svg"
                alt="logo"
                className="logo"
              />
            ) : (
              <img
                src="/Assets/navbar-assets/tomistaking-dark.svg"
                alt="logo"
                className="logo"
              />
            )
          ) : route === 6 ? (
            theme === "light-theme" ? (
              <img
                src="\Assets\navbar-assets\settings-light.svg"
                alt="logo"
                className="logo"
              />
            ) : (
              <img
                src="\Assets\navbar-assets\settings-dark.svg"
                alt="logo"
                className="logo"
              />
            )
          ) :
            route === 7 ? (
              theme === "light-theme" ? (
                <img
                  src="\Assets\navbar-assets\noti-light.svg"
                  alt="logo"
                  className="logo"
                />
              ) : (
                <img
                  src="\Assets\navbar-assets\noti-dark.svg"
                  alt="logo"
                  className="logo"
                />
              )
            )
              : route === 8 ? (
                theme === "light-theme" ? (
                  <img
                    src="\Assets\navbar-assets\dop-light.svg"
                    alt="logo"
                    className="logo"
                  />
                ) : (
                  <img
                    src="\Assets\navbar-assets\dop-dark.svg"
                    alt="logo"
                    className="logo"
                  />
                )
              ) : route === 9 ? (
                theme === "light-theme" ? (
                  <img
                    src="\Assets\navbar-assets\atomic-light.svg"
                    alt="logo"
                    className="logo"
                  />
                ) : (
                  <img
                    src="\Assets\navbar-assets\atomic-dark.svg"
                    alt="logo"
                    className="logo"
                  />
                )
              )
                :
                (
                  ""
                )}

          <div className="navbarlefticons">
            <input type="checkbox" onClick={toggleTheme} id="darkmode-toggle" />
            <label for="darkmode-toggle">
              <svg
                className="sun"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Hicon / Bold / Sun 1" clip-path="url(#clip0_733_4677)">
                  <g id="Sun 1">
                    <g id="Vector">
                      <path
                        d="M9.54544 0.891768C9.54544 0.590522 9.30123 0.346313 8.99998 0.346313C8.69874 0.346313 8.45453 0.590522 8.45453 0.891768V2.34631C8.45453 2.64756 8.69874 2.89177 8.99998 2.89177C9.30123 2.89177 9.54544 2.64756 9.54544 2.34631V0.891768Z"
                        fill="white"
                      />
                      <path
                        d="M3.72882 2.84922C3.51581 2.63621 3.17045 2.63621 2.95743 2.84922C2.74442 3.06223 2.74442 3.40759 2.95743 3.62061L3.98595 4.64913C4.19897 4.86214 4.54433 4.86214 4.75734 4.64913C4.97036 4.43611 4.97036 4.09075 4.75734 3.87774L3.72882 2.84922Z"
                        fill="white"
                      />
                      <path
                        d="M15.0425 3.62061C15.2555 3.4076 15.2555 3.06223 15.0425 2.84922C14.8295 2.63621 14.4842 2.63621 14.2711 2.84922L13.2426 3.87774C13.0296 4.09075 13.0296 4.43611 13.2426 4.64913C13.4556 4.86214 13.801 4.86214 14.014 4.64913L15.0425 3.62061Z"
                        fill="white"
                      />
                      <path
                        d="M8.99998 3.98268C6.28877 3.98268 4.09089 6.18055 4.09089 8.89177C4.09089 11.603 6.28877 13.8009 8.99998 13.8009C11.7112 13.8009 13.9091 11.603 13.9091 8.89177C13.9091 6.18055 11.7112 3.98268 8.99998 3.98268Z"
                        fill="white"
                      />
                      <path
                        d="M0.999983 8.34631C0.698737 8.34631 0.454529 8.59052 0.454529 8.89177C0.454529 9.19301 0.698737 9.43722 0.999983 9.43722H2.45453C2.75578 9.43722 2.99998 9.19301 2.99998 8.89177C2.99998 8.59052 2.75578 8.34631 2.45453 8.34631H0.999983Z"
                        fill="white"
                      />
                      <path
                        d="M15.5454 8.34631C15.2442 8.34631 15 8.59052 15 8.89177C15 9.19301 15.2442 9.43722 15.5454 9.43722H17C17.3012 9.43722 17.5454 9.19301 17.5454 8.89177C17.5454 8.59052 17.3012 8.34631 17 8.34631H15.5454Z"
                        fill="white"
                      />
                      <path
                        d="M4.75734 13.9058C4.97036 13.6928 4.97036 13.3474 4.75734 13.1344C4.54433 12.9214 4.19897 12.9214 3.98595 13.1344L2.95743 14.1629C2.74442 14.3759 2.74442 14.7213 2.95743 14.9343C3.17045 15.1473 3.51581 15.1473 3.72882 14.9343L4.75734 13.9058Z"
                        fill="white"
                      />
                      <path
                        d="M14.014 13.1344C13.801 12.9214 13.4556 12.9214 13.2426 13.1344C13.0296 13.3474 13.0296 13.6928 13.2426 13.9058L14.2711 14.9343C14.4842 15.1473 14.8295 15.1473 15.0425 14.9343C15.2555 14.7213 15.2555 14.3759 15.0425 14.1629L14.014 13.1344Z"
                        fill="white"
                      />
                      <path
                        d="M9.54544 15.4372C9.54544 15.136 9.30123 14.8918 8.99998 14.8918C8.69874 14.8918 8.45453 15.136 8.45453 15.4372V16.8918C8.45453 17.193 8.69874 17.4372 8.99998 17.4372C9.30123 17.4372 9.54544 17.193 9.54544 16.8918V15.4372Z"
                        fill="white"
                      />
                    </g>
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_733_4677">
                    <rect
                      width="17.4545"
                      height="17.4545"
                      fill="white"
                      transform="translate(0.272705 0.164551)"
                    />
                  </clipPath>
                </defs>
              </svg>

              <svg
                width="18"
                className="moon"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Hicon / Bold / Moon">
                  <g id="Moon">
                    <path
                      id="Vector"
                      d="M9.39572 9.62921C8.03708 7.27599 8.2882 5.17447 8.48808 3.8265L8.50888 3.68734C8.55072 3.40872 8.59099 3.1405 8.60463 2.92214C8.61272 2.79259 8.61395 2.65026 8.5917 2.51346C8.56984 2.37898 8.5173 2.1992 8.37619 2.04716C8.05559 1.70177 7.60537 1.79765 7.3622 1.87532C7.07323 1.96762 6.7121 2.15094 6.27392 2.40393C2.53454 4.56286 1.25334 9.34438 3.41227 13.0838C5.5712 16.8231 10.3527 18.1043 14.0921 15.9454C14.5303 15.6924 14.8696 15.4713 15.094 15.2672C15.2829 15.0955 15.591 14.7535 15.4522 14.3032C15.3911 14.105 15.2617 13.9696 15.1561 13.8834C15.0488 13.7957 14.9249 13.7256 14.8087 13.6679C14.6128 13.5705 14.3604 13.4713 14.0982 13.3682L13.9672 13.3166C12.6999 12.8157 10.7544 11.9824 9.39572 9.62921Z"
                      fill="#ADA7A7"
                    />
                  </g>
                </g>
              </svg>
            </label>
            <div class="dropdown notificationicon" onClick={handleDropdownToggle}>
              <button
                class="dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-bs-auto-close="outside"
                onClick={() => setDot(false)}
              >
                <img
                  src="\Assets\Sidebar-icons\notification.svg"
                  alt="notificationimg"
                  className="notificationimg"
                />
                {dot &&
                  <img
                    src="\Assets\Sidebar-icons\notificationdot.svg"
                    alt="notificationdot"
                    className="notificationdot"
                  />
                }
              </button>
              {/* <ul class="dropdown-menu"> */}
              <ul className={`dropdown-menu ${isOpen ? 'show' : ''}`}>
                <div className="upper-div">
                  <h6>
                    Notifications
                    <a onClick={() => {
                      handleDropdownClose();
                      setCurrentTab("six");
                      setroute(7);
                    }}>
                      See all</a>
                  </h6>
                  {/* <a href="#">
                    <img
                      src="\Assets\tomitoken-assets\more.svg"
                      alt="img"
                      className="img-fluid"
                    />
                  </a> */}
                </div>
                <div className="bottom-content">
                  {noti?.map((elem) => {
                    // console.log("time",moment(elem?.createdAt).fromNow())
                    return (
                      <div className={elem?.isRead === false ? "single-noti active" : 'single-noti'} onClick={() => readfun(elem?.isRead, elem?._id)}>
                        <h6>{elem?.title}! 🐳</h6>
                        <p>${parseFloat(elem?.total).toFixed(2)} {elem?.orderType} on {elem?.exchange} exchange.</p>
                        <span className="laterago active">{moment(elem?.createdAt).fromNow()}</span>
                      </div>
                    )
                  })}
                </div>
              </ul>
            </div>
            <div className="profileicon">
              <div className="mainprofileimg">
                <img
                  onClick={() => {
                    // history.push({
                    //   pathname: "/dashboard",
                    //   state: { route: "6", tabKey: "fourth" },
                    // });
                    setroute(6);
                    setCurrentTab("fourth");
                    // window.location.reload();
                  }}
                  src={userObj?.profileImage}
                  alt="profileimg"
                  className="profileimg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Navbar;
