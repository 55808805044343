import React, { useState, useEffect } from 'react'
import { Nav } from 'react-bootstrap';
import TVL from './TVL';
import StakedUser from './StakedUser';
import AverageStake from './AverageStake';
import TokensReleased from './TokensReleased';
import RewardClaim from './RewardClaim';
import { Api_Url } from "../../utils/ApiUrl";
import Stakingtoken from './Stakingtoken';
import axios from "axios";


const TomiStaking = () => {
    const [activeTab, setActiveTab] = useState('link-1');
    const aToken = localStorage.getItem("accessToken");
    const [price, setPrice] = useState(0.0);
    const [value, setValue] = useState([])
    const handleSelect = (eventKey) => {
        setActiveTab(eventKey);
    };
    const [update, setUpdate] = useState(0);
    const getStakingData = () => {
        // console.log("overeviewwwww")
        axios.get(`${Api_Url}/staking/staking-data`, { headers: { "Authorization": `Bearer ${aToken}` } })
            .then((response) => {
                setValue(response.data.data)
            })
            .catch((err) => {

            })
    }

    const getTomiPrice = async () => {
        const config = {
            method: "get",
            url: `${Api_Url}/market-cap/tomi-price`,
            headers: {
                Authorization: "Bearer " + aToken,
            },
        };
        await axios(config)
            .then((res) => {
                const resData = res?.data?.data;
                setPrice(parseFloat(resData?.price).toFixed(2));
            })
            .catch((err) => {
                if (err?.response?.status == 401) {
                    //   localStorage.removeItem("accessToken");
                    //   history.push("/");
                }
                // toast.error(err?.response?.data.msg, {
                //   position: "top-right",
                //   autoClose: 2000,
                // });
            });
    };
    // console.log("datallllll-----", price)
    useEffect(() => {
        getStakingData()
        getTomiPrice()
    }, [aToken])
    return (
        <section className="tomitoken">
            <div className="parent-overview" style={{ marginBottom: "25px" }}>
                <Nav className='maintabs' variant="pills" activeKey={activeTab} onSelect={handleSelect}>
                    <Nav.Item>
                        <Nav.Link className='tablinks' eventKey="link-1" onClick={() => setUpdate(0)}>TVL</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link className='tablinks' eventKey="link-2" onClick={() => setUpdate(1)}>Staking Tokens</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link className='tablinks' eventKey="link-3" onClick={() => setUpdate(2)}>Staked Users</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link className='tablinks' eventKey="link-4" onClick={() => setUpdate(3)}>Average Staked</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link className='tablinks' eventKey="link-5" onClick={() => setUpdate(4)}>Reward Claim</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link className='tablinks' eventKey="link-6" onClick={() => setUpdate(5)}>Tokens Release</Nav.Link>
                    </Nav.Item>
                </Nav>
                {update === 0 ? <>
                    <div className="right-tab">
                        <div className="inner-left">
                            <img
                                src="\Assets\new-icons\valuelocked.svg"
                                alt="img"
                                className="img-fluid"
                            />
                            <div className="inner-text">
                                <h6>Total Value </h6>
                                <p>Locked</p>
                            </div>
                        </div>
                        <div className="inner-right">
                            <h5>
                                ${(parseInt(value?.totalValueLocked) * price).toLocaleString()}
                                <p style={{ display: "block", color: "#BCBCBC", fontSize: "14px" }}>
                                    {parseInt(value?.totalValueLocked).toLocaleString()} TOMI
                                </p>
                            </h5>

                        </div>
                    </div>
                </> :
                    update === 1 ?
                        <>

                            <div className="right-tab">
                                <div className="inner-left">
                                    <img
                                        src="\Assets\new-icons\stakingtoken.svg"
                                        alt="img"
                                        className="img-fluid"
                                    />
                                    <div className="inner-text">
                                        <h6>Total TOMI </h6>
                                        <p>Staked</p>
                                    </div>
                                </div>
                                <div className="inner-right">
                                    <h5>{parseInt(value?.totalValueLocked).toLocaleString()}  TOMI</h5>
                                </div>
                            </div>
                        </> :
                        update === 2 ?
                            <>
                                <div className="right-tab">
                                    <div className="inner-left">
                                        <img
                                            src="\Assets\new-icons\stakeduser.svg"
                                            alt="img"
                                            className="img-fluid"
                                        />
                                        <div className="inner-text">
                                            <h6>Total Staked  </h6>
                                            <p>Users</p>
                                        </div>
                                    </div>
                                    <div className="inner-right">
                                        <h5>{value?.totalStakedUsers.toLocaleString()} Users</h5>
                                    </div>
                                </div>
                            </> :
                            update === 3 ?
                                <>
                                    <div className="right-tab">
                                        <div className="inner-left">
                                            <img
                                                src="\Assets\new-icons\averagestaked.svg"
                                                alt="img"
                                                className="img-fluid"
                                            />
                                            <div className="inner-text">
                                                <h6>Total Average  </h6>
                                                <p>Staked</p>
                                            </div>
                                        </div>
                                        <div className="inner-right">
                                            <h5>{parseInt(value?.totalAvgAmount).toLocaleString()} TOMI</h5>
                                        </div>
                                    </div>
                                </>

                                :
                                update === 4 ?
                                    <>
                                        <div className="right-tab">
                                            <div className="inner-left">
                                                <img
                                                    src="\Assets\new-icons\rewardclaim.svg"
                                                    alt="img"
                                                    className="img-fluid"
                                                />
                                                <div className="inner-text">
                                                    <h6>Total Reward  </h6>
                                                    <p>Given</p>
                                                </div>
                                            </div>
                                            <div className="inner-right">
                                                <h5>{parseInt(value?.totalClaimedReward).toLocaleString()} TOMI</h5>
                                            </div>
                                        </div>
                                    </>

                                    :
                                    update === 5 ?
                                        <>
                                            <div className="right-tab">
                                                <div className="inner-left">
                                                    <img
                                                        src="\Assets\new-icons\tokenrelease.svg"
                                                        alt="img"
                                                        className="img-fluid"
                                                    />
                                                    <div className="inner-text">
                                                        <h6>Total Tokens  </h6>
                                                        <p>Release</p>
                                                    </div>
                                                </div>
                                                <div className="inner-right">
                                                    <h5>{parseInt(value?.totalTokensRelease).toLocaleString()} TOMI</h5>
                                                </div>
                                            </div>
                                        </>
                                        : ""}
            </div>
            {activeTab === 'link-1' && (
                <>
                    <TVL />
                </>
            )}
            {activeTab === 'link-2' && (
                <>
                    <Stakingtoken />

                </>
            )}
            {activeTab === 'link-3' && (
                <>
                    <StakedUser />

                </>
            )}
            {activeTab === 'link-4' && (
                <>
                    <AverageStake />

                </>
            )}
            {activeTab === 'link-5' && (
                <>
                    <RewardClaim />

                </>
            )}
            {activeTab === 'link-6' && (
                <>
                    <TokensReleased />
                </>
            )}
        </section>
    )
}

export default TomiStaking