import React, { useState, useRef, useEffect } from "react";
import "./Settings.scss";
import ChangeEmail from "./changeEmail/ChangeEmail";
import GoogleAuthenticator from "./googleAuthenticator/GoogleAuthenticator";
import { Link, useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { Api_Url } from "../../utils/ApiUrl";
import axios from "axios";

const Settings = () => {
  const history = useHistory();
  const val = localStorage.getItem("accessToken");
  
  const userObj = JSON.parse(localStorage.getItem("user"));
  const [authenticatorOnOffBool, setAuthenticatorOnOffBool] = useState(
    userObj?.google2FEnabled
  );
  const [authenticatorLinkedBool, setAuthenticatorLinkedBool] = useState(
    userObj?.google2FLinked
  );
  const [userNameChangeBool, setUserNameChangebool] = useState(false);
  const [imageError, setImageError] = useState("");

  const [profileImage, setProfileImage] = useState("");
  const [baseImage, setBaseImage] = useState("");

  const [userName, setUserName] = useState("");
  const [userNameError, setUserNameError] = useState("");

  const [secretKey, setSecretKey] = useState("");
  const [showError, setError] = useState("");

  const [values, setValues] = useState(["", "", "", "", "", ""]);
  const [OTP, setOTP] = useState("");

  const refs = useRef([]);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setImageError("");
    setBaseImage("");
    setProfileImage("");
  };
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const [data, setData] = useState();

  const handleClose1 = () => {
    setShow1(false);
    setOTP("");
    setValues(["", "", "", "", "", ""]);
    setUserName("");
    setUserNameChangebool(false);
    setUserNameError("");
    // setAuthenticatorOnOffBool(() => {
    //   const userObjTemp = JSON.parse(localStorage.getItem("user"));
    //   return userObjTemp?.google2FEnabled;
    // });
  };
  const handleShow1 = () => {
    setShow1(true);
  };
  const aToken = localStorage.getItem("accessToken");
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => {
    setShow3(false);
  };
  const handleShow3 = () => {
    setShow3(true);
    setUserNameChangebool(true);
  };

  const handleChange = (index, event) => {
    const { value } = event.target;

    // Check if the entered value is a number
    if (!/^\d*$/.test(value)) {
      event.target.value = "";
      return;
    }

    let updatedValues = [...values];

    updatedValues[index] = value.substring(0, 1); // Restrict to single digit

    setValues(updatedValues);
    const tempOTP = updatedValues.join("");
    setOTP(tempOTP);

    if (value.length > 0 && index < values.length - 1) {
      refs.current[index + 1].focus(); // Move to the next input field
    }
    if (OTP.length == 6) {
      //   userLogin();
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && index > 0 && values[index] === "") {
      refs.current[index - 1].focus(); // Move to the previous input field on Backspace
    }
  };
  const handleFocus = (event) => {
    if (OTP?.length == values?.length) {
      event.target.setSelectionRange(0, 0); // Move the cursor to the start of the input field
    }
  };
      //  console.log("response: ", data);

  const activateDeactivateGoogleAuth = async (boolVar) => {
    const data = {
      otpToken: OTP,
      google2FEnabled: boolVar,
      ...(!OTP && secretKey && { otpSecretKey: secretKey }),
    };
    const config = {
      method: "post",
      url: `${Api_Url}/users/enable-2FA`,
      data: data,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        // console.log("response: ", resData);
        setAuthenticatorLinkedBool(resData?.google2FLinked);
        setAuthenticatorOnOffBool(resData?.google2FEnabled);
        const temp = JSON.parse(localStorage.getItem("user"));
        temp.google2FLinked = resData?.google2FLinked;
        temp.google2FEnabled = resData?.google2FEnabled;
        localStorage.setItem("user", JSON.stringify(temp));
        handleClose1();
      })
      .catch((err) => {
        setError(err?.response?.data?.message);

        // if (err?.response?.status == 401) {
        //   localStorage.removeItem("accessToken");
        //   history.push("/");
        // }
        // toast.error(err?.response?.data.msg, {
        //   position: "top-right",
        //   autoClose: 2000,
        // });
      });
  };

  const changeUserName = async () => {
    const data = {
      userName: userName,
      otpToken: OTP,
    };
    const config = {
      method: "post",
      url: `${Api_Url}/users/update-username`,
      data: data,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        handleClose1();
      })
      .catch((err) => {
        setUserNameError(err?.response?.data?.message);
        if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/");
        }
        // toast.error(err?.response?.data.msg, {
        //   position: "top-right",
        //   autoClose: 2000,
        // });
      });
  };

  const uploadImages = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase645(file);
    setBaseImage(base64);
    setProfileImage(file);
  };
  const convertBase645 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const changeProfilePicture = async () => {
    if (profileImage) {
      const formData = new FormData();
      formData.append("userProfileImage", profileImage);
      const config = {
        method: "patch",
        url: `${Api_Url}/users/update-profile`,
        data: formData,
        headers: {
          Authorization: "Bearer " + val,
        },
      };
      await axios(config)
        .then((res) => {
          const resData = res?.data?.data;
          const temp = JSON.parse(localStorage.getItem("user"));
          temp.profileImage = resData?.profileImage;
          localStorage.setItem("user", JSON.stringify(temp));

          handleClose();
        })
        .catch((err) => {
          setImageError(err?.response?.data?.message);
          if (err?.response?.status == 401) {
            localStorage.removeItem("accessToken");
            history.push("/");
          }
          // toast.error(err?.response?.data.msg, {
          //   position: "top-right",
          //   autoClose: 2000,
          // });
        });
    } else {
      setImageError("Please Upload an Image");
    }
  };

  // useer flow
  const getUseer = () => {
      axios.get(`${Api_Url}/users/profile`, { headers: { "Authorization": `Bearer ${aToken}` } })
        .then((response) => {
         setData(response?.data?.data)
        })
        .catch((err) => {
          // setOpen1(false)
          // toast.error(err.response?.data.msg, {
          //     position: "top-center",
          //     autoClose: 2000,
          // });
        })
  }

  useEffect(() => {
    getUseer()
  }, [aToken]);
// console.log("dataaaa",data)
  return (
    <>
      <div className="settings-wrapper">
        <div className="settings-item-wrapper">
          <div className="settings-body">
            <div className="settings-content">
              <div className="settings-header hvydvvvcvdvvc">
                <img
                  src={userObj?.profileImage}
                  alt="img"
                  className="img-fluid profile-img"
                />
                <h5>Profile Image</h5>
              </div>
            </div>
            <div className="settings-action-btn w-100">
              <button onClick={handleShow} className="update-pass-btn ms-auto">
              Update Profile Image
              </button>
            </div>
          </div>
        </div>
        <div className="settings-item-wrapper">
          <div className="settings-body">
            <div className="settings-content">
              <div className="settings-header">
                <h5>Update your username</h5>
              </div>
              <p>{data?.userName}</p>
            </div>
            <div className="settings-action-btn w-100">
              <button onClick={handleShow3} className="update-pass-btn ms-auto">
                Update Username
              </button>
              {/* <ChangeEmail /> */}
            </div>
          </div>
        </div>
        <div className="settings-item-wrapper">
          <div className="settings-body">
            <div className="settings-content">
              <div className="settings-header">
                <h5>Update your email </h5>
              </div>
              <p>{data?.email}</p>
            </div>
            <div className="settings-action-btn w-100">
              {/* <button className='update-pass-btn'>Update your Email</button> */}
              <ChangeEmail email = {data?.email}  getUseer={getUseer}/>
            </div>
          </div>
        </div>
        <div className="settings-item-wrapper">
          <div className="settings-body">
            <div className="settings-content">
              <div className="settings-header">
                <h5>Update your password </h5>
              </div>
              <p>
                through the button below You will be redirected to a new page
                and must follow the instructions.
              </p>
            </div>
            <div className="settings-action-btn w-100">
              <Link to={"/updatepassword?2fa=" + data?.google2FEnabled}>
                <button className="update-pass-btn ms-auto">
                Update password 
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="settings-item-wrapper">
          <div className="settings-body">
            <div className="settings-content">
              <div className="settings-header">
                <h5>
                  Google Authenticator{" "}
                  <p>
                    {authenticatorLinkedBool ? (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="19"
                          viewBox="0 0 18 19"
                          fill="none"
                        >
                          <path
                            d="M9 17C13.125 17 16.5 13.625 16.5 9.5C16.5 5.375 13.125 2 9 2C4.875 2 1.5 5.375 1.5 9.5C1.5 13.625 4.875 17 9 17Z"
                            stroke="#00F026"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M5.8125 9.49994L7.935 11.6224L12.1875 7.37744"
                            stroke="#00F026"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>{" "}
                        <p className="ms-1">Linked</p>
                      </>
                    ) : (
                      <>
                        <img
                          src="\Assets\notlinked.svg"
                          alt="img"
                          className="img-fluid me-2"
                        />
                        Not Linked
                      </>
                    )}
                  </p>
                </h5>
              </div>
              <p>Protect your account and transactions.</p>
            </div>
            <div className="settings-action-btn">
              <GoogleAuthenticator
                handleClose1={handleClose1}
                show1={handleClose1}
                handleShow1={handleShow1}
                authenticatorOnOffBool={authenticatorOnOffBool}
                setAuthenticatorOnOffBool={setAuthenticatorOnOffBool}
                authenticatorLinkedBool={authenticatorLinkedBool}
                setAuthenticatorLinkedBool={setAuthenticatorLinkedBool}
                setSecretKey={setSecretKey}
                secretKey={secretKey}
                setOTP={setOTP}
                OTP={OTP}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-wrapper-box"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Profile Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="upload-img-profile">
            <p>Profile Image</p>
            <div className="upload">
              <label htmlFor="upload">
                {" "}
                {baseImage ? (
                  <img
                    src={baseImage}
                    alt="img"
                    className="img-fluid upload-img"
                  />
                ) : (
                  <>
                    <div>
                      <img
                        src="\Assets\upload.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </div>

                    {/* <h6>Upload user</h6> */}
                  </>
                )}
              </label>
              <input
                type="file"
                className="d-none"
                id="upload"
                onChange={uploadImages}
                accept="image/png, image/jpeg"
              />
            </div>
            <div style={{ position: "unset" }} className="text-danger mt-2">
              <small>{imageError}</small>
            </div>
          </div>
          <button
            className="btn-change-profile"
            onClick={() => {
              changeProfilePicture();
            }}
          >
            Change Profile Image
          </button>
        </Modal.Body>
      </Modal>

      <Modal
        show={show1}
        onHide={handleClose1}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-wrapper-box"
      >
        <Modal.Header closeButton>
          {userNameChangeBool ? (
            <Modal.Title>Authenticate</Modal.Title>
          ) : (
            <Modal.Title>Disable Google Authenticator</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <div className="ffafa-modal">
            <div className="parentinputs">
              {values.map((value, index) => (
                <input
                  autoFocus={index === 0}
                  onFocus={handleFocus}
                  className="code"
                  type="text"
                  placeholder="_"
                  key={index}
                  value={value}
                  onChange={(event) => handleChange(index, event)}
                  onKeyDown={(event) => handleKeyDown(index, event)}
                  ref={(inputRef) => (refs.current[index] = inputRef)}
                />
              ))}
              {/* <input className="code" type="text" placeholder="_" />
              <input className="code" type="text" placeholder="_" />
              <input className="code" type="text" placeholder="_" />
              <input className="code" type="text" placeholder="_" />
              <input className="code" type="text" placeholder="_" />
              <input className="code" type="text" placeholder="_" /> */}
            </div>
            {userNameChangeBool ? (
              <div style={{ position: "unset" }} className="text-danger mt-2">
                <small>{userNameError}</small>
              </div>
            ) : (
              <div style={{ position: "unset" }} className="text-danger mt-2">
                <small>{showError}</small>
              </div>
            )}

            {OTP.length < 6 ? (
              <div className="digitbtns">
                <button>{6 - OTP?.length} digits left</button>
              </div>
            ) : (
              <>
                {userNameChangeBool ? (
                  <div className="endbtn">
                    <button onClick={() => changeUserName()}>
                      Change Username
                    </button>
                  </div>
                ) : (
                  <div className="endbtn">
                    <button onClick={() => activateDeactivateGoogleAuth(false)}>
                      Turn Off
                    </button>
                  </div>
                )}
              </>
            )}
            {/* <div className="digitbtns">
              <button
                onClick={() => {
                  handleClose1();
                  handleShow2();
                }}
              >
                2 digits left
              </button>
            </div> */}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={show2}
        onHide={handleClose2}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-wrapper-box"
      >
        <Modal.Header closeButton>
          <Modal.Title>Recover 2FA Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="recover2fa">
            <img
              src="\Assets\codedisabled.svg"
              alt="img"
              className="img-fluid"
            />
            <h6>2FA Code Disabled</h6>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={show3}
        onHide={handleClose3}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-wrapper-box"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update your username</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="update-user">
            <input
              type="text"
              placeholder="New Username"
              value={userName}
              onChange={(e) => {
                setUserName(e.target.value);
                setUserNameError("");
              }}
            />
            <div style={{ position: "unset" }} className="text-danger mt-2">
              <small>{userNameError}</small>
            </div>
          </div>
          <button
            className="btn-change-profile"
            onClick={() => {
              if (!userName) {
                setUserNameError("Please Enter New Username");
              } else {
                handleShow1();
                handleClose3();
              }
            }}
          >
            Done
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Settings;
