import React, { useState,useEffect } from 'react'
import { Nav } from 'react-bootstrap';
import './tomitdns.scss'
import TdnsNewActiveUser from './TdnsNewActiveUser';
import DomainMinted from './DomainMinted';
import DomainOffer from './DomainOffer';
import DomainDetails from './DomainDetails';
import TdnsIncome from './TdnsIncome';
import { Api_Url } from "../../utils/ApiUrl";
import axios from "axios";


const Tomitdns = () => {
    const [activeTab, setActiveTab] = useState('link-1');

    const handleSelect = (eventKey) => {
        setActiveTab(eventKey);
    };
    const [update, setUpdate] = useState(0);
    const aToken = localStorage.getItem("accessToken");
    const [value, setValue] = useState([])
    const getOverview = () => {
        // console.log("overeviewwwww")
        axios.get(`${Api_Url}/tdns/overview`, { headers: { "Authorization": `Bearer ${aToken}` } })
            .then((response) => {
            //    console.log("hereereree",response)
               setValue(response.data.data) 
            })
            .catch((err) => {
                
            })
    }
    // console.log("datallllll-----",value)
    useEffect(() => {
        getOverview()
    }, [aToken])

    return (
        <section className="tomitoken">
            <div className="parent-overview" style={{ marginBottom: "25px" }}>
            <Nav className='maintabs' variant="pills" activeKey={activeTab} onSelect={handleSelect}>
                <Nav.Item>
                    <Nav.Link className='tablinks' eventKey="link-1" onClick={() => setUpdate(0)}>New Active Users</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link className='tablinks' eventKey="link-2" onClick={() => setUpdate(1)}>Domains Minted</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link className='tablinks' eventKey="link-3" onClick={() => setUpdate(2)}>Domain Offers</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link className='tablinks' eventKey="link-4" onClick={() => setUpdate(3)}>Domain Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link className='tablinks' eventKey="link-5" onClick={() => setUpdate(4)}>tDNS Income</Nav.Link>
                </Nav.Item>
            </Nav>
            {update === 0 ? <>
                    <div className="right-tab">
                        <div className="inner-left">
                            <img
                                src="\Assets\new-icons\newactiveuser-tdns.svg"
                                alt="img"
                                className="img-fluid"
                            />
                            <div className="inner-text">
                                <h6>Total Active  </h6>
                                <p>Users</p>
                            </div>
                        </div>
                        <div className="inner-right">
                            <h5>{value?.totalTdnsUsers}</h5>
                        </div>
                    </div>
                </> :
                    update === 1 ?
                        <>
                            <div className="right-tab">
                                <div className="inner-left">
                                    <img
                                        src="\Assets\new-icons\tdns.svg"
                                        alt="img"
                                        className="img-fluid"
                                    />
                                    <div className="inner-text">
                                        <h6>Total Domains </h6>
                                        <p>Minted</p>
                                    </div>
                                </div>
                                <div className="inner-right">
                                    <h5>{value?.domainsMinted}</h5>
                                </div>
                            </div>
                        </> : 
                        update === 2 ?
                        <>
                            <div className="right-tab">
                                <div className="inner-left">
                                    <img
                                        src="\Assets\new-icons\totalnoofoffers.svg"
                                        alt="img"
                                        className="img-fluid"
                                    />
                                    <div className="inner-text">
                                        <h6>Total Number of  </h6>
                                        <p>Offers</p>
                                    </div>
                                </div>
                                <div className="inner-right">
                                    <h5>{value?.totalBids}</h5>
                                </div>
                            </div>
                        </> :
                         update === 3 ?
                         <>
                             <div className="right-tab" style={{maxWidth: "310px"}}>
                                 <div className="inner-left">
                                     <img
                                         src="\Assets\new-icons\totalnoofoffers.svg"
                                         alt="img"
                                         className="img-fluid"
                                     />
                                     <div className="inner-text">
                                         <h6>Total Amount in </h6>
                                         <p>Offers</p>
                                     </div>
                                 </div>
                                 <div className="inner-right">
                                     <h5>{parseInt(value?.amountOfBids)} TOMI</h5>
                                 </div>
                             </div>
                         </> :
                         update === 4 ?
                         <>
                             <div className="right-tab">
                                 <div className="inner-left">
                                     <img
                                         src="\Assets\new-icons\tdnsincome.svg"
                                         alt="img"
                                         className="img-fluid"
                                     />
                                     <div className="inner-text">
                                         <h6>Total tDNS </h6>
                                         <p>Income</p>
                                     </div>
                                 </div>
                                 <div className="inner-right">
                                     <h5>{parseInt(value?.tdnsIncome)} TOMI</h5>
                                 </div>
                             </div>
                         </>
                        : ""}
                          </div>
            {activeTab === 'link-1' && (
                <>
               <TdnsNewActiveUser />
                </>
            )}
            {activeTab === 'link-2' && (
                <>
                  <DomainMinted />
                </>
            )}
            {activeTab === 'link-3' && (
                <>
                  <DomainOffer />
                </>
            )}
            {activeTab === 'link-4' && (
                <>
                    <DomainDetails />
                </>
            )}
             {activeTab === 'link-5' && (
                <>
                    <TdnsIncome />
                </>
            )}
        </section>
    )
}

export default Tomitdns